import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./list-rides.css";
import PathConstants from "../../routes/PathConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import { throwError } from "rxjs";
import DashboardService from "../../Services/DashboardService";
import RideStatus from "../../constants/RideStatus";
import FiltersConstants from "../../constants/FiltersConstants";

export default function DashboardListRides(props) {
  const [list, setList] = useState([]);
  const [lastPage, setLastPage] = useState([]);
  const [query, setQuery] = useState([]);
  const dashboardService = useService(DashboardService);
  const [currentPage, setCurrentPage] = useState(1);
  // const [rides, setRides] = useState([]);
  const [loading, setLoading] = useState(false);
  const filtersConstants = FiltersConstants();
  useEffect(() => {
    setLoading(true);
    setLastPage(1);
    setCurrentPage(1);
    dashboardService.getMyRides(props.status).subscribe(res => {
      setList(res.result.data);
      setLoading(false);
      setLastPage(res.result.last_page);
      setCurrentPage(res.result.current_page);
      setQuery(props);
      // setRideDetail("ride detail");
      // setShowElement(OVERVIEW);
    });
  }, [props]);

  const getHoursFromMilliSec = ms => {
    return Math.trunc(ms / (1000 * 60 * 60));
  };

  const getMinsFromMilliSec = ms => {
    return Math.trunc((ms / (1000 * 60)) % 60);
  };
  const fetchMoreData = () => {
    setLoading(true);
    if (currentPage >= lastPage) {
      setLoading(false);
      return;
    }
    var filter = {};
    if (props.status?.status) {
      filter["status"] = props.status?.status;
    }
    filter["page"] = currentPage + 1;

    dashboardService.getMyRides(filter).subscribe({
      next: response => {
        if (response.status) {
          var newList = list.concat(response.result.data);
          setList(
            newList //.concat(Array.from(response.result.data)),
          );
          setCurrentPage(currentPage + 1);
        }
        setLoading(false);
      },
      error: err => {
        // alert("network error");
        setLoading(false);
        setList(
          [] //.concat(Array.from(response.result.data)),
        );

        return throwError(() => err);
      },
    });
  };
  return (
    <div>
      <div className="search-results container px-0 mt-3">
        <div className="grid search-list">
          <div className="grid-body">
            {/* <div className="row"> */}
            {/* <!-- BEGIN SEARCH RESULT --> */}
            {/* new Table here */}
            <div className="mt-5 row d-flex justify-content-center">
              <div
                className="col-10 text-left table"
                style={{ overflow: "hidden" }}
              >
                {/* <div className="table-responsive"> */}
                {/* <strong>Ride Details</strong> */}
                <InfiniteScroll
                  dataLength={list?.length}
                  next={fetchMoreData}
                  // hasMore={true}
                  hasMore={currentPage < lastPage}
                  loader={
                    loading && (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    )
                  }
                >
                  {list &&
                    list.length > 0 &&
                    list.map(trip => {
                      return (
                        <div
                          className="app-border-color shadow mb-5 card card-stepper bg-image hover-overlay"

                          key={trip._id}
                        >
                          <div className="1app-bg-color card-body p-1">
                            <div className="p-2 col border rounded py-2">
                              <div className="py-2 justify-content-between border1 d-flex flex-row border-bottom">
                                <strong className="col text-left text-dark">
                                  {new Date(
                                    parseInt(trip.departure)
                                  ).toDateString()}{" "}
                                  {new Date(
                                    parseInt(trip.departure)
                                  ).toLocaleTimeString("en-US", {
                                    hour: "2-digit",
                                    hour12: true, // Delete for 24-hour format
                                    minute: "2-digit",
                                  })}
                                </strong>
                                <strong className="col text-right text-success">
                                  {trip.status === RideStatus.PENDING
                                    ? RideStatus.PENDING_STRING
                                    : trip.status === RideStatus.CANCELLED
                                      ? RideStatus.CANCELLED_STRING
                                      : RideStatus.RUNNING_STRING}
                                </strong>
                              </div>
                              <div className=" d-flex flex-column flex-md-row justify-content-between">
                                <div className="col px-0 py-2 justify-content-between 1border d-flex flex-row border-bottom 1rounded">
                                  <div className="px-0 align-self-center py-2 col-auto d-flex flex-column text-center">
                                    <div className="text-capitalize">
                                      {trip.from_loc?.city}
                                    </div>
                                    <div className="align-self-center text-capitalize text-muted small">
                                      {trip.from_loc?.state}
                                    </div>
                                    {new Date(
                                      parseInt(trip.departure)
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      hour12: true, // Delete for 24-hour format
                                      minute: "2-digit",
                                    })}
                                  </div>
                                  <div className="align-self-center py-2 align-self-center col text-center">
                                    {trip.distance && (
                                      <small>
                                        {trip.distance}
                                        Km.
                                      </small>
                                    )}
                                    {trip.distance && (
                                      <hr className="my-0 flex-fill track-line" />
                                    )}
                                    {!trip.distance && (
                                      <hr className="my-0 mt-0 flex-fill track-line" />
                                    )}
                                    { }
                                    {trip.arrival && trip.departure && (
                                      <small>
                                        {getHoursFromMilliSec(
                                          trip.arrival - trip.departure
                                        )}
                                        {" Hrs. "}
                                        {getMinsFromMilliSec(
                                          trip.arrival - trip.departure
                                        )}
                                        {" Mins."}
                                      </small>
                                    )}
                                  </div>
                                  <div className="px-0 align-self-center py-2 col-auto d-flex flex-column text-center">
                                    <div className="text-capitalize">
                                      {trip.to_loc?.city}
                                    </div>
                                    <div className="text-capitalize text-muted small">
                                      {trip.to_loc?.state}
                                    </div>
                                    {trip.arrival &&
                                      new Date(
                                        parseInt(trip.arrival)
                                      ).toLocaleTimeString("en-US", {
                                        hour: "2-digit",
                                        hour12: true, // Delete for 24-hour format
                                        minute: "2-digit",
                                      })}
                                  </div>
                                </div>
                                <div className="col col-md-4 py-2 text-center border-bottom ">
                                  <div className="row">
                                    <div className="col">
                                      <div className="row">
                                        <div className="col  p-0">
                                          <strong>
                                            Rs.
                                            {trip.fare}/Pax
                                          </strong>
                                        </div>
                                      </div>
                                      <hr className="m-1" />
                                      <div className="row">
                                        <div className="col  p-0">
                                          {trip.current_vacancy
                                            ? trip.current_vacancy
                                            : trip.maxpax}
                                          {" of "} {trip.maxpax} Avl
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {trip.driver && (
                                  <div className="col m-1 p-2 border-right">
                                    <div className="text-center">
                                      <strong>Driver </strong>{" "}
                                    </div>
                                    <small className="text-capitalize pr-2">
                                      {trip.driver?.name + ", "}
                                    </small>
                                    <small className="text-capitalize pr-2">
                                      {trip.driver?.age + ", "}
                                    </small>
                                    <small className="text-capitalize pr-2">
                                      {filtersConstants.getGenderString(
                                        trip.driver?.gender
                                      )}
                                      { }
                                    </small>
                                  </div>
                                )}
                                {trip.vehicle && (
                                  <div className="col m-1 p-2 border-right">
                                    <div className="text-center">
                                      <strong>Vehicle</strong>
                                    </div>
                                    {trip.vehicle?.brand && (
                                      <small className="text-capitalize">
                                        {trip.vehicle.brand + " "}
                                      </small>
                                    )}
                                    <small>{trip.vehicle?.vehicle_no}</small>
                                  </div>
                                )}
                                <div className="col rounded m-1 p-2 text-right">
                                  <Link
                                    className="font-weight-bold app-bg-color text-dark btn"
                                    to={
                                      PathConstants.RIDE_DETAIL + "/" + trip._id
                                    }
                                  >
                                    Details
                                  </Link>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* {loading && "Loading"} */}
                </InfiniteScroll>
                {/* </div> */}
              </div>
            </div>
            {/* new table ends here */}
          </div>
        </div>
      </div>
    </div>
  );
}
