import { Route, Routes } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import PathConstants from "../../routes/PathConstants";
import DashboardService from "../../Services/DashboardService";
import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import DashboardSummary from "./DashboardOverview";
import DashboardBookingOverview from "./DashboardBooking";
import DashboardRideOverview from "./DashboardRide";

export default function DashboardOverview() {
  const { user } = AuthUser();
  const navigate = useNavigate();
  const [rideDetail, setRideDetail] = useState();
  const [rideOverview, setRideOverview] = useState();
  const [bookingOverview, setBookingOverview] = useState();
  const [loadingRide, setLoadingRide] = useState(false);
  const [loadingBooking, setLoadingBooking] = useState(false);

  const dashboardService = useService(DashboardService);

  useEffect(() => {
    getOverview();
  }, []);

  const getOverview = () => {
    setLoadingRide(true);
    setLoadingBooking(true);
    dashboardService.getRideOverview().subscribe({
      next: response => {
        if (response.status) {
          setRideOverview(response.result);
        }
        setLoadingRide(false);
      },
      error: err => {
        setLoadingRide(false);
      },
    });
    dashboardService.getBookingOverview().subscribe({
      next: response => {
        if (response.status) {
          setBookingOverview(response.result);
          setLoadingBooking(false);
        }
        setLoadingBooking(false);
      },
      error: err => {
        // alert(err.message);
        setLoadingBooking(false);
      },
    });
  };

  const createNewRide = () => {
    navigate(PathConstants.CREATE_RIDE, { replace: true });
  };

  return (
    <div className="contaniner">
      <div className="row justify-content-center">
        <div className="mt-2 px-2 col-10 col-md-8">
          <div className="row">
            <div className="mt-2 px-2 col d-flex justify-content-between">
              <button className="btn btn-outline-dark" onClick={getOverview}>
                {loadingRide ? "Loading..." : "Reload"}
                {loadingRide && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                )}
              </button>
              <button className="btn btn-outline-dark" onClick={createNewRide}>
                New Ride
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col main pt-5 mt-3">
              <div className="row mb-3 justify-content-center">
                <div className="col-xl-3 col-sm-6 py-2">
                  <div className="card bg-success text-dark h-100 text-center">
                    <div className="card-body app-bg-color">
                      <h6 className="text-uppercase">Earnings (INR)</h6>
                      {loadingBooking && (
                        <span className="spinner-border" role="status"></span>
                      )}
                      {!loadingBooking && (
                        <h1 className="display-4">
                          {bookingOverview?.earning}
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mb-3 justify-content-center">
                <div className="col-xl-3 col-sm-6 py-2">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-uppercase small mb-2">
                        <strong>Rides</strong>
                      </p>
                      <h5 className="mb-0">
                        {!loadingRide && <strong>{rideOverview?.total}</strong>}
                        {loadingRide && (
                          <span className="spinner-border" role="status"></span>
                        )}
                        {/* <small className="text-success ms-2">
                          <i className="fas fa-arrow-up fa-sm pe-1"></i>13,48%
                        </small> */}
                      </h5>

                      <hr />

                      <p className="text-uppercase text-muted small mb-2">
                        Completed
                      </p>
                      {loadingRide && (
                        <span className="spinner-border" role="status"></span>
                      )}
                      {!loadingRide && (
                        <h5 className="text-muted mb-0">
                          {rideOverview?.completed}
                        </h5>
                      )}
                      <hr />

                      <p className="text-uppercase text-muted small mb-2">
                        Upcoming
                      </p>
                      {loadingRide && (
                        <span className="spinner-border" role="status"></span>
                      )}
                      {!loadingRide && (
                        <h5 className="text-muted mb-0">
                          {rideOverview?.upcoming}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 py-2">
                  <div className="card">
                    <div className="card-body">
                      <p className="text-uppercase small mb-2">
                        <strong>Bookings</strong>
                      </p>
                      <h5 className="mb-0">
                        {loadingBooking && (
                          <span className="spinner-border" role="status"></span>
                        )}{" "}
                        {!loadingBooking && (
                          <strong>{bookingOverview?.total}</strong>
                        )}
                        {/* <small className="text-success ms-2">
                          <i className="fas fa-arrow-up fa-sm pe-1"></i>13,48%
                        </small> */}
                      </h5>
                      <hr />
                      <p className="text-uppercase text-muted small mb-2">
                        Completed
                      </p>
                      {loadingBooking && (
                        <span className="spinner-border" role="status"></span>
                      )}{" "}
                      {!loadingBooking && (
                        <h5 className="text-muted mb-0">
                          {bookingOverview?.completed}
                        </h5>
                      )}
                      <hr />
                      <p className="text-uppercase text-muted small mb-2">
                        Upcoming
                      </p>
                      {loadingBooking && (
                        <span className="spinner-border" role="status"></span>
                      )}{" "}
                      {!loadingBooking && (
                        <h5 className="text-muted mb-0">
                          {bookingOverview?.upcoming}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
