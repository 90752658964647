export default class StringUtils {
  /**
   * 
   * @param {Date} value 
   * @returns 
   * Format new Date input value to "YYYY-MM-ddTHH:mm:SS"
   */
  static dateToDateTimeString(value) {
    let dateTimeArray = value.toLocaleString().split(',');
    let dateArray = dateTimeArray[0].split("/");
    let timeArray = dateTimeArray[1].trim();
    value = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0] +
      "T" + timeArray;
    return value;
  }
}
