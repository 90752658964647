import { EMPTY, Observable, throwError } from "rxjs";
import { MessageContextProps } from "../contexts/MessageContext";
import ErrorHandling from "./ErrorHandling";
import IService from "./IService";

import api from "../config/Api";
import { catchError } from "rxjs/operators";
import { User } from "../models/User";
import AuthUser from "../Components/Common/AuthUser";
import Ride from "../models/Ride";
import { map } from "rxjs/operators";
import RideStatus from "../constants/RideStatus";

export default class RideService extends ErrorHandling implements IService {
  constructor(context: MessageContextProps) {
    super(context);
  }

  search<Ride>(query: any): Observable<Ride[]> {
    return api.get<Ride[]>(`/rides/search`, undefined, query).pipe(
      // map((result: any) => result),
      // map(obj => ({name: obj.name, address: obj.address})),
      catchError((err: any) => {
        // console.log(err);
        this.handleError("Ride error");
        // return throwError(() => new Error("test"));
        // throw err;
        // return throwError(err); //Rethrow it back to component
        return throwError(() => err);
      })
    );
  }
  get<Ride>(id: string): Observable<Ride[]> {
    return api.get<Ride[]>(`/rides/` + id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        console.log(err);
        return [];
      })
    );
  }
  // post<Ride>(arg: Ride): Observable<Ride[]> {
  post<Ride>(obj: object): Observable<Ride> {
    console.log(obj);
    let path = `/rides`;
    if ('id' in obj) {
      path += "/" + obj.id;
    }
    let response = api
      .post<any>(path, obj)
      .pipe
      // catchError((err: any) => {
      // this.handleError("Ride error");
      //   // console.log(err);
      // return err;
      // })
      ();
    // console.log(response);
    return response;
  }
  patch<T>(arg: T): Observable<T[]> {
    throw new Error("Method not implemented.");
  }
  // post<T>(arg: T): Observable<T[]> {
  //   throw new Error("Method not implemented.");
  // }
  delete<T>(id: string): Observable<T[]> {
    let response = api.delete<any>(`/rides`, id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        // console.log(err);
        throw err;
      })
    );
    // console.log(response);
    return response;
  }

  cancel<T>(id: string): Observable<T[]> {
    return this.updateStatus(id, RideStatus.CANCELLED);
  }

  updateStatus<T>(ride_id: string, status: string): Observable<T[]> {
    let response = api
      .post<any>(`/rides/status`, { ride_id: ride_id, status: status })
      .pipe(
        catchError((err: any) => {
          this.handleError("Ride error");
          // console.log(err);
          throw err;
        })
      );
    // console.log(response);
    return response;
  }
  searchLocation<Ride>(query: any): Observable<Ride[]> {
    return api
      .get<Ride[]>(`/locations/search`, undefined, { query: query })
      .pipe(
        catchError((err: any) => {
          this.handleError("Ride error");
          console.log(err);
          return [];
        })
      );
  }

  addLocation<T>(query: object): Observable<T[]> {
    let response = api.post<any>(`/locations/add`, query).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        throw err;
      })
    );
    // console.log(response);
    return response;
  }
}
