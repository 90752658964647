import { EMPTY, Observable } from "rxjs";
import { MessageContextProps } from "../contexts/MessageContext";
import ErrorHandling from "./ErrorHandling";
import IService from "./IService";

import api from "../config/Api";
import { catchError } from "rxjs/operators";
import { User } from "../models/User";
import AuthUser from "../Components/Common/AuthUser";
import Ride from "../models/Ride";
import { Vehicle } from "../models/Vehicle";

export default class VehicleService extends ErrorHandling implements IService {
  constructor(context: MessageContextProps) {
    super(context);
  }

  get<Vehicle>(id: string): Observable<Vehicle[]> {
    return api.get<Vehicle[]>(`/rides/` + id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        console.log(err);
        return [];
      })
    );
  }

  getVehicles(filters?: object): Observable<void> {
    return api.get<void>(`/vehicles`, undefined, filters).pipe(
      catchError((err: any) => {
        this.handleError("Vehicle dashboard error");
        console.log(err);
        return [];
      })
    );
  }

  getMyVehicles(): Observable<void> {
    return api.get<void>(`/vehicles/mine`).pipe(
      catchError((err: any) => {
        this.handleError("Vehicle dashboard error");
        console.log(err);
        return [];
      })
    );
  }
  createVehicle(obj: object): Observable<Ride> {
    console.log(obj);
    let response = api.post<any>(`/vehicles`, obj).pipe(
      catchError((err: any) => {
        this.handleError("Vehicle error");
        // console.log(err);
        return err;
      })
    );
    // console.log(response);
    return response;
  }
  // post<Ride>(arg: Ride): Observable<Ride[]> {
  post<Vehicle>(obj: object): Observable<Vehicle> {
    return api
      .post<any>(`/vehicles`, obj)
      .pipe
      // catchError((err: any) => {
      // this.handleError("Ride error");
      //   // console.log(err);
      // return err;
      // })
      ();
  }
  patch<T>(arg: T): Observable<T[]> {
    throw new Error("Method not implemented.");
  }

  delete(id: string): Observable<any> {
    // console.log(id);
    return api.delete<any>(`/vehicles`, id).pipe(
      catchError((err: any) => {
        this.handleError("Vehicle error");
        // console.log(err);
        throw err;
      })
    );
  }
}
