import { Route, Routes } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import DashboardRide from "./DashboardRide";
import DashboardBooking from "./DashboardBooking";
import DashboardOverview from "./DashboardOverview";
import DashboardDriver from "./DashboardDriver";
import DashboardVehicle from "./DashboardVehicle";
import UserProfile from "../user/UserProfile";
import TabConstants from "../../routes/TabConstants";
import PathConstants from "../../routes/PathConstants";

export default function DashboardSideBar(props) {

  const { user } = AuthUser();
  const navigate = useNavigate();

  const [showElement, setShowElement] = useState(props.activeTab
    ? props.activeTab : TabConstants.DASHBOARD_OVERVIEW);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const dashboardService = useService(DashboardService);

  const loadOverview = (type) => {
    handleClose();
    setShowElement(type);
    console.log(type);
    if (type === TabConstants.DASHBOARD_OVERVIEW) {
      navigate(PathConstants.DASHBOARD_OVERVIEW);
    }
    else if (type === TabConstants.DASHBOARD_RIDE) {
      navigate(PathConstants.DASHBOARD_RIDES);
    }
    else if (type === TabConstants.DASHBOARD_BOOKINGS) {
      navigate(PathConstants.DASHBOARD_BOOKINGS);
    }
    else if (type === TabConstants.DASHBOARD_DRIVER) {
      navigate(PathConstants.DASHBOARD_DRIVERS);
    }
    else if (type === TabConstants.DASHBOARD_VEHICLE) {
      navigate(PathConstants.DASHBOARD_VEHICLES);
    }
    else if (type === TabConstants.DASHBOARD_PROFILE) {
      navigate(PathConstants.DASHBOARD_PROFILE);
    }
  };

  return (
    <div className="container1" id="main">
      <Offcanvas className="bg-dark" show={show} onHide={handleClose}>
        <Offcanvas.Header className="text-dark app-bg-color" closeButton>
          <Offcanvas.Title className="1text-dark">Dashboard</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav flex-column sticky-top pl-0 mt-3">
            <li className="nav-item">
              <a className={"text-warning"} href="#">
                {user?.role}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.DASHBOARD_OVERVIEW
                    ? "nav-link text-primary"
                    : "nav-link"
                }
                onClick={() => loadOverview(TabConstants.DASHBOARD_OVERVIEW)}
                href="#"
              >
                Overview
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.DASHBOARD_RIDE ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadOverview(TabConstants.DASHBOARD_RIDE)}
              >
                Rides
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.DASHBOARD_BOOKINGS ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadOverview(TabConstants.DASHBOARD_BOOKINGS)}
              >
                Bookings
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.DASHBOARD_DRIVER ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadOverview(TabConstants.DASHBOARD_DRIVER)}
              >
                Drivers
              </a>
            </li>

            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.DASHBOARD_VEHICLE ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadOverview(TabConstants.DASHBOARD_VEHICLE)}
              >
                Vehicles
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.DASHBOARD_PROFILE ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadOverview(TabConstants.DASHBOARD_PROFILE)}
              >
                Profile
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <div className="rounded-0 btn btn-dark" onClick={handleShow}>
        {">"}
      </div>
    </div>
    // {/* </div> */}
  );
}
