import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import CreateUpdateVehicle from "./CreateUpdateVehicle";
import { MDBBtn, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import VehicleService from "../../Services/VehicleService";
import { toast } from "react-toastify";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export default function DashboardVehicle() {
  const LIST_VEHICLE = 1;
  const ADD_VEHICLE = 2;
  const [myVehicles, setMyVehicles] = useState();
  const [activeTab, setActiveTab] = useState();
  const [loading, setLoading] = useState();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });
  const vehicleService = useService(VehicleService);

  useEffect(() => {
    getMyVehicles();
  }, []);

  const getMyVehicles = () => {
    setLoading(true);
    setActiveTab(LIST_VEHICLE);
    vehicleService.getMyVehicles().subscribe({
      next: response => {
        if (response.status) {
          setMyVehicles(response.result?.data);
        }
        setLoading(false);
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const confirmDeleteDriver = id => {
    setOpenConfirmationDialog(true);
    var title = "Delete Vehicle";

    var body =
      "Do you want to delete the vehicle? Once confirmed, it cannot be revoked.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
      vehicle_id: id,
    }));
  };
  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    // console.log(confirmation);
    if (confirmation) {
      deleteVehicle(dialogParams.vehicle_id);
    }
  }
  const deleteVehicle = id => {
    vehicleService.delete(id).subscribe({
      next: response => {
        if (response.status) {
          toast.dismiss();
          toast.success(response.message);
          getMyVehicles();
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const createNewVehicle = () => {
    setActiveTab(ADD_VEHICLE);
    setLoading(false);
    return;
  };

  return (
    <div className="contaniner">
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      <div className="row justify-content-center">
        <div className="mt-2 px-2 col-10 col-md-8 ">
          {/* <button className="btn btn-light border" onClick={getMyVehicles}>
        List
      </button> */}
          <div className="card text-center">
            <div className="card-header bg-dark">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <a
                    className={
                      activeTab === LIST_VEHICLE
                        ? "nav-link active"
                        : "text-white nav-link"
                    }
                    onClick={getMyVehicles}
                    href="#"
                  >
                    List
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      activeTab === ADD_VEHICLE
                        ? "nav-link active"
                        : "text-white nav-link"
                    }
                    onClick={createNewVehicle}
                    href="#"
                  >
                    Add New Vehicle
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              {activeTab === LIST_VEHICLE &&
                (loading ? (
                  <div>Loading...</div>
                ) : myVehicles && myVehicles.length > 0 ? (
                  <MDBListGroup light>
                    {myVehicles.map(myVehicles => {
                      return (
                        <div>
                          <MDBListGroupItem
                            key={myVehicles._id}
                            className="app-bg-color mb-2 d-flex justify-content-between align-items-center"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/avatar.jpg"
                                alt=""
                                style={{ width: "45px", height: "45px" }}
                                className="rounded-circle"
                              />
                              <div className="ms-3">
                                <p className="fw-bold mb-1 text-uppercase">
                                  {myVehicles.brand}
                                </p>
                                <p className="text-muted mb-0 text-uppercase">
                                  {myVehicles.vehicle_no}
                                </p>
                              </div>
                            </div>
                            <div>
                              {/* <MDBBtn size="sm" rounded color="link">
                            Edit
                          </MDBBtn> */}
                              <div
                                className="btn btn-dark"
                                size="sm"
                                rounded
                                color="link"
                                onClick={() =>
                                  confirmDeleteDriver(myVehicles._id)
                                }
                              >
                                Delete
                              </div>
                            </div>
                          </MDBListGroupItem>
                          {/* <hr /> */}
                        </div>
                        // <div
                        //   key={myVehicles._id}
                        //   className="mb-2 row border border-dark rounded"
                        // >
                        //   <h5 className="card-title">{myVehicles.name}</h5>
                        //   <p className="card-text">
                        //     With supporting text below as a natural lead-in to
                        //     additional content.
                        //   </p>
                        //   <div className="btn btn-primary">Edit</div>
                        // </div>
                      );
                    })}
                  </MDBListGroup>
                ) : (
                  <div>No Vehicles Found</div>
                ))}
              {activeTab === ADD_VEHICLE && (
                <CreateUpdateVehicle></CreateUpdateVehicle>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
