import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import CreateUpdateVehicle from "../rides/CreateUpdateVehicle";

export default function AddNewVehicleDialog(props) {
  useEffect(() => {
  }, [props?.open]);

  const handleClose = () => {
    props.sendConfirmationToParent(false);
  };

  function handleConfirmationFromChild(confirmation) {
    if (confirmation) {
      props.sendConfirmationToParent(true);
    }

  }

  return (
    <>
      <Modal show={props.open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Add New Vehicle"}</Modal.Title>
        </Modal.Header>

        <CreateUpdateVehicle
          sendConfirmationToParent={handleConfirmationFromChild}
        ></CreateUpdateVehicle>
      </Modal>
    </>
  );
}
