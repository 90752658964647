import AuthUser from "../Common/AuthUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import UserProfile from "./UserProfile";
import UserBookings from "./UserBookings";
import RolesConstants from "../../constants/RolesConstants";
import CoTravelQueryListComponent from "../rides/CoTravelQueryListComponent";
import CoTravelRequestList from "./CoTravelRequestList";
import RequestConstants from "../../constants/RequestConstants";
import UserAccountSideBar from "./UserAccountSideBar";

export default function UserAccount() {
  const PROFILE = "P";
  const BOOKING = "B";
  const TRAVEL_QUERY = "Q";
  const TRAVEL_REQUEST_SENT = "S";
  const TRAVEL_REQUEST_RECEIVED = "R";
  const { user } = AuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  let profile =
    location.state && location.state.user_id ? location.state : null;
  const [showElement, setShowElement] = useState(PROFILE);
  const [show, setShow] = useState(false);
  const requestConstants = RequestConstants();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const dashboardService = useService(DashboardService);

  const loadProfile = () => {
    handleClose();
    setShowElement(PROFILE);
  };

  const loadBookings = () => {
    handleClose();
    setShowElement(BOOKING);
  };
  const loadTravelQueries = () => {
    handleClose();
    setShowElement(TRAVEL_QUERY);
  };
  const loadTravelRequests = (type) => {
    handleClose();
    setShowElement(type);
  };
  return (
    <div className="container1" id="main">
      {/* <Offcanvas className="bg-dark" show={show} onHide={handleClose}>
        <Offcanvas.Header className="text-dark bg-warning1 app-bg-color" closeButton>
          <Offcanvas.Title className="1text-dark">My Account</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav flex-column sticky-top pl-0 mt-3">
            <li className="nav-item">
              <a
                className={
                  showElement === PROFILE ? "nav-link text-primary" : "nav-link"
                }
                onClick={loadProfile}
                href="#"
              >
                Profile
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === BOOKING ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={loadBookings}
              >
                My Bookings
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TRAVEL_QUERY ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={loadTravelQueries}
              >
                Travel Queries
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TRAVEL_REQUEST_SENT ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadTravelRequests(TRAVEL_REQUEST_SENT)}
              >
                Travel Requests Sent
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TRAVEL_REQUEST_RECEIVED ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadTravelRequests(TRAVEL_REQUEST_RECEIVED)}
              >
                Travel Requests Rcvd
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas> */}
      <UserAccountSideBar></UserAccountSideBar>
      {/* /////////////// */}
      {/* <div className="row1"> */}
      {/* {!show && ( */}
      {/* <div className="rounded-0 btn btn-dark" onClick={handleShow}> */}
      {/* <FontAwesomeIcon
            className="h-25 text-white px-1"
            icon={faArrowRight}
          /> */}
      {/* {">"} */}
      {/* </div> */}
      {/* )} */}
      {/* <div className="col" id="main"> */}
      {showElement === PROFILE &&
        <UserProfile profile={profile}></UserProfile>}
      {showElement === BOOKING && user?.role === RolesConstants.RIDER && (
        <UserBookings></UserBookings>
      )}
      {showElement === TRAVEL_QUERY && user?.role !== RolesConstants.OPERATOR && (
        <CoTravelQueryListComponent isMine={true}></CoTravelQueryListComponent>
      )}
      {user?.role !== RolesConstants.OPERATOR && (
        showElement === TRAVEL_REQUEST_SENT && <CoTravelRequestList requestType={requestConstants.RequestTypes.SENT}></CoTravelRequestList>
      )}
      {user?.role !== RolesConstants.OPERATOR && (
        showElement === TRAVEL_REQUEST_RECEIVED && <CoTravelRequestList requestType={requestConstants.RequestTypes.RECEIVED}></CoTravelRequestList>
      )}
      {/* </div> */}
    </div>
    // {/* </div> */}
  );
}
