import { Route, Routes } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import PathConstants from "../../routes/PathConstants";
import DashboardService from "../../Services/DashboardService";
import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import DashboardListRides from "./DashboardListRides";
import RideStatus from "../../constants/RideStatus";

export default function DashboardRide() {
  const { user } = AuthUser();
  const navigate = useNavigate();
  const [rideDetail, setRideDetail] = useState();
  const [showAll, setShowAll] = useState(false);
  const [showOthers, setShowOthers] = useState(false);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);

  const dashboardService = useService(DashboardService);

  const getAllRides = () => {
    setShowOthers(false);
    setShowAll(true);
    // console.log("all");
  };

  const getCompletedRides = () => {
    setShowAll(false);
    // console.log("completed");
    setStatus(RideStatus.COMPLETED);
    setShowOthers(true);
  };

  const getUpcomingRides = () => {
    setShowAll(false);
    // console.log("completed");
    setStatus(RideStatus.UPCOMING);
    setShowOthers(true);
  };
  const getCancelledRides = () => {
    setShowAll(false);
    // console.log("completed");
    setStatus(RideStatus.CANCELLED);
    setShowOthers(true);
  };
  const getPendingRides = () => {
    setShowAll(false);
    // console.log("completed");
    setStatus(RideStatus.PENDING);
    setShowOthers(true);
  };

  useEffect(() => {
    getRideDashboard();
  }, []);

  const getRideDashboard = () => {
    setLoading(true);
    dashboardService.getRideDashboard().subscribe(res => {
      console.log(res);
      setRideDetail(res.result);
      setLoading(false);
      // setRideDetail("ride detail");
      // setShowElement(OVERVIEW);
    });
  };
  const createNewRide = () => {
    navigate(PathConstants.CREATE_RIDE, { replace: true });
  };

  return (
    <div className="mt-3 container">
      <div className="row justify-content-center">
        {/* // <div className="col main pt-5 mt-3"> */}
        <div className="px-0 col-11 col-lg-8">
          {/* <div className="row"> */}
          <div className="mt-2 px-2 col d-flex justify-content-between">
            <button className="btn btn-outline-dark" onClick={getRideDashboard}>
              {loading ? "Loading..." : "Reload"}
            </button>
            <button className="btn btn-outline-dark" onClick={createNewRide}>
              New Ride
            </button>
          </div>
          {/* </div> */}
          {loading && (
            <div className="text-center">
              <span className="text-center spinner-border" role="status"></span>
            </div>
          )}
          {!loading && (
            <div>
              <div className="row mb-3 justify-content-center">
                <div className="col-xl-3 col-sm-6 py-2">
                  <div className="shadow card text-dark text-center h-100">
                    <div
                      onClick={getAllRides}
                      className="btn card-body app-bg-color"
                    >
                      <h6 className="text-uppercase">Total Rides</h6>
                      <h1 className="display-4">{rideDetail?.total}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mb-3 justify-content-center">
                <div className="text-center  col-xl-3 col-sm-6 py-2">
                  <div className="card">
                    <div className="card-body">
                      <p
                        onClick={getCompletedRides}
                        className="btn btn-outline-dark text-uppercase small mb-2"
                      >
                        <strong>Completed</strong>
                      </p>
                      <h5 className="mb-0">
                        <strong>{rideDetail?.completed}</strong>
                        {/* <small className="text-success ms-2">
                          <i className="fas fa-arrow-up fa-sm pe-1"></i>13,48%
                        </small> */}
                      </h5>

                      <hr />

                      <p
                        onClick={getUpcomingRides}
                        className="btn btn-outline-dark text-uppercase 1text-muted small mb-2"
                      >
                        Upcoming
                      </p>
                      <h5 className="text-muted mb-0">
                        {rideDetail?.upcoming}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="text-center col-xl-3 col-sm-6 py-2">
                  <div className="card">
                    <div className="card-body">
                      <p
                        onClick={getCancelledRides}
                        className="btn btn-outline-dark text-uppercase small mb-2"
                      >
                        <strong>Cancelled</strong>
                      </p>
                      <h5 className="mb-0">
                        <strong>{rideDetail?.cancelled}</strong>
                      </h5>

                      <hr />

                      <p
                        onClick={getPendingRides}
                        className="btn btn-outline-dark text-uppercase 1text-white small mb-2"
                      >
                        Pending
                      </p>
                      <h5 className="text-muted mb-0">{rideDetail?.pending}</h5>
                    </div>
                  </div>
                </div>
              </div>
              {showOthers && (
                <DashboardListRides status={{ status }}></DashboardListRides>
              )}
              {showAll && <DashboardListRides></DashboardListRides>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
