import { Route, Routes } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import "../../styles/common.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { throwError } from "rxjs";
import PathConstants from "../../routes/PathConstants";
import CoTravelService from "../../Services/CoTravelService";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import FiltersConstants from "../../constants/FiltersConstants";
import RequestConstants from "../../constants/RequestConstants";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import UserProfileService from "../../Services/UserProfileService";

export default function CoTravelRequestList(props) {
  const requestConstants = RequestConstants();
  const [totalRequests, setTotalRequests] = useState(0);
  const [result, setResult] = useState([]);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();
  const coTravelService = useService(CoTravelService);
  const userProfileService = useService(UserProfileService);
  const [sortBy, setSortBy] = useState();
  const [filterBy, setFilterBy] = useState();
  const filtersConstants = FiltersConstants();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedStatusAction, setSelectedStatusAction] = useState();
  const [selectedRequestId, setSelectedRequestId] = useState();
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoadingPage(true);
    fetchMoreData();
  }, [reload]);

  const reloadMyRequests = () => {
    setResult([]);
    setCurrentPage(0);
    setLastPage(0);
    setReload(!reload); //above use effect will run on change of reload value
  };
  const fetchRequests = () => {
    var inputs = {
      page: currentPage + 1,
      size: filtersConstants.BATCH_SIZE,
      sort_by: sortBy,
    };

    coTravelService.myRequests(inputs, props?.requestType).subscribe({
      next: response => {
        if (response.status) {
          setLastPage(response?.result?.last_page);
          setTotalRequests(response.result?.total);
          var newList = result.concat(response.result.data);
          setResult(
            newList //.concat(Array.from(response.result.data)),
          );
          setCurrentPage(currentPage + 1);
        }
        setLoading(false);
        setLoadingPage(false);
      },
      error: err => {
        setResult(
          [] //.concat(Array.from(response.result.data)),
        );
        setLoading(false);
        return throwError(() => err);
      },
    });
  };
  const fetchMoreData = () => {
    // console.log("fetching...");
    setLoading(true);
    // setLoadingPage(true);
    if (currentPage > lastPage) {
      setLoading(false);
      setLoadingPage(false);
      return;
    }
    fetchRequests();
  };

  const showUserProfile = (travelRequest) => {
    var userId = props.requestType === requestConstants.RequestTypes.SENT ? travelRequest.requested_to : travelRequest.requested_by;
    navigate(PathConstants.USER_PROFILE + "/" + userId);
    return;
  }
  function handleSortByAction(sortBy) {
    // console.log(sortBy);
    setSortBy(sortBy);
    reloadMyRequests();
  }
  function handleFilterByAction(filterBy) {
    setFilterBy(filterBy);
    reloadMyRequests();
  }
  const takeRequestAction = () => {
    coTravelService.takeRequestAction(selectedRequestId, selectedStatusAction).subscribe({
      next: response => {
        if (response.status) {
          toast.success("Submitted successfully");
          reloadMyRequests();
        }
      },
      error: err => {
        // return throwError(() => err);
      },
    });
  }
  const confirmRequestAction = (reqId, selectedStatusAction) => {
    setOpenConfirmationDialog(true);
    setSelectedStatusAction(selectedStatusAction);
    setSelectedRequestId(reqId);
    var title = "Accept co-travel request?";
    let statusString = requestConstants.getStatusString(selectedStatusAction);
    var body =
      "The sender will be notified once " + statusString + ", you can communicate and plan your travel.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));

  };
  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    // console.log(selectedQuery);
    if (confirmation) {
      takeRequestAction();
    }
  }
  const getPhoneForRequest = (reqId) => {

    userProfileService.getPhoneForRequest(reqId).subscribe({
      next: response => {
        if (response.status) {
          // toast.success("Submitted successfully");
          // alert(response.result);
          toast.success(response.result, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // transition: Bounce,
          });
        }
      },
      error: err => {
        // return throwError(() => err);
      },
    });

  }
  const AgeSexString = (travelRequest) => {
    let str = "";
    travelRequest = travelRequest.travelRequest;
    var user = props.requestType === requestConstants.RequestTypes.SENT ? travelRequest.receiver : travelRequest.sender;
    if (user?.profile?.age) {
      str += user.profile.age;
      if (user?.profile?.gender) {
        str += " | " + user.profile.gender;
      }
    } else if (user?.profile?.gender) {
      str += user.profile.gender;
    }
    return <div>{str}</div>;
  }
  return (
    <div>
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      <div className="search-results container mt-3">
        <div className="row justify-content-center">
          <aside className="col-lg-8">
            {/* //////////// */}
            {/* <SearchFilters
              sendSortByToParent={handleSortByAction}
              sendFilterByToParent={handleFilterByAction}
            ></SearchFilters> */}
            {/* ////////Sorting Drpdown Ends///////// */}
            {!loadingPage && (
              <div className="grid search-list">
                <div className="grid-body">
                  {/* <div className="row"> */}
                  {/* <!-- BEGIN SEARCH RESULT --> */}
                  {/* new Table here */}
                  <div className="text-danger">
                    {totalRequests +
                      " Co-Travel Request " + (props.requestType === requestConstants.RequestTypes.SENT ? "Sent" : "Received")
                      // new Date(inputsParams.date).toLocaleDateString()
                    }
                  </div>
                  {totalRequests > 0 && (
                    <div className="row d-flex justify-content-center">
                      <div
                        className="col-10 text-left table"
                        style={{ overflow: "hidden" }}
                      >
                        {/* <div className="table-responsive"> */}
                        {/* <strong>Ride Details</strong> */}
                        <InfiniteScroll
                          dataLength={result?.length}
                          next={fetchMoreData}
                          hasMore={true}
                          loader={
                            loading && (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            )
                          }
                        >
                          {result.map(travelRequest => {
                            return (
                              <div
                                className="shadow mb-5 card card-stepper bg-image hover-overlay"
                                style={{ borderColor: "lawngreen" }}
                                key={travelRequest._id}
                              >
                                <div className="card-body p-0">
                                  <div className="col d-flex flex-column flex-md-row">
                                    <div className="px-0 pb-2 col-auto col-md-4 justify-content-left 1border d-flex flex-row border-bottom 1rounded">

                                      <div className="d-flex flex-row flex-md-column 1col-4">
                                        <a href="" className="col-auto 1mr-2" onClick={() => showUserProfile(travelRequest)}><img
                                          src="/avatar.jpg"
                                          alt=""
                                          style={{ width: "30px", height: "30px" }}
                                          className="rounded-circle"
                                        />
                                        </a>
                                        <div className="col d-flex flex-column"><div className="1py-2">{props.requestType === requestConstants.RequestTypes.SENT ? travelRequest?.receiver?.name : travelRequest?.sender?.name}
                                        </div>
                                          <AgeSexString travelRequest={travelRequest}></AgeSexString>
                                        </div>

                                      </div>
                                      <div>
                                        <div className="text-center text-warning btn btn-sm 1btn-outline-success" onClick={() => getPhoneForRequest(travelRequest._id)}><FontAwesomeIcon className="px-1" icon={faPhoneAlt} />
                                        </div>
                                      </div>
                                      {/* <div className="col text-left"><div className="text-danger font-weight-bold">Along with
                                      </div><div><span className="pr-2 text-success">1 Male
                                      </span><span className="pr-2 text-primary">1 Female
                                          </span><span className="pr-2 text-warning">1 Child
                                          </span></div></div> */}

                                    </div>
                                    <div className="p-0 col justify-content-between 1border 1d-flex 1flex-row border-bottom 1rounded">
                                      <div className="row justify-content-between">
                                        <div className="py-2 col col-md-8 justify-content-left justify-content-cmd-enter 1border d-flex flex-row 1border-bottom 1rounded">
                                          <div className="text-break col-auto d-flex flex-column text-center">
                                            <div className="text-left text-capitalize">
                                              {travelRequest.travelquery?.from_loc?.city === travelRequest.travelquery?.to_loc?.city && travelRequest.travelquery?.from_loc?.landmark ? travelRequest.travelquery?.from_loc?.landmark : travelRequest.travelquery?.from_loc?.city}
                                            </div>
                                            <div className="text-left text-capitalize text-muted small">
                                              {travelRequest.travelquery?.from_loc?.city === travelRequest.travelquery?.to_loc?.city ? travelRequest.travelquery?.from_loc?.city : travelRequest.travelquery?.from_loc?.state}
                                            </div>
                                            <small className="text-left badge badge-warning">{filtersConstants.getTimeSlotString(travelRequest.travelquery?.slot)}
                                            </small>
                                          </div>

                                          <div className="text-break col-auto d-flex flex-column text-center">
                                            <div className="text-left text-capitalize">
                                              {travelRequest.travelquery?.from_loc?.city === travelRequest.travelquery?.to_loc?.city && travelRequest.travelquery?.to_loc?.landmark ? travelRequest.travelquery?.to_loc?.landmark : travelRequest.travelquery?.to_loc?.city}
                                            </div>
                                            <div className="text-left text-capitalize text-muted small">
                                              {travelRequest.travelquery?.from_loc?.city === travelRequest.travelquery?.to_loc?.city ? travelRequest.travelquery?.to_loc?.city : travelRequest.travelquery?.to_loc?.state}
                                            </div>
                                          </div>

                                        </div>
                                        <div className="align-self-center border-left col-auto col-md 1d-flex 1flex-row text-left ">

                                          {props.requestType === requestConstants.RequestTypes.RECEIVED
                                            && (travelRequest.status === requestConstants.Statuses.ACCEPTED ||
                                              travelRequest.status === requestConstants.Statuses.REJECTED) &&
                                            (<div className="d-flex flex-column 1m-1 py-2 text-right">
                                              <div
                                                className={"mb-2 p-2 badge " + (requestConstants.Statuses.ACCEPTED === travelRequest.status ? "badge-success" :
                                                  (requestConstants.Statuses.REJECTED === travelRequest.status ? "badge-danger" : "badge-warning text-dark"))}

                                              >
                                                {requestConstants.getStatusString(travelRequest.status)}
                                              </div>
                                            </div>
                                            )}
                                          {(
                                            props.requestType === requestConstants.RequestTypes.RECEIVED
                                            && travelRequest.status !== requestConstants.Statuses.ACCEPTED &&
                                            travelRequest.status !== requestConstants.Statuses.REJECTED
                                          ) &&
                                            (
                                              <div className="d-flex flex-column 1m-1 py-2 text-right">

                                                <div
                                                  // value={travelRequest}
                                                  className="mb-2 btn btn-outline-success"
                                                  onClick={() => confirmRequestAction(travelRequest._id, requestConstants.Statuses.ACCEPTED)}
                                                >
                                                  Accept
                                                </div>{" "}
                                                <div
                                                  // value={travelRequest}
                                                  className="btn btn-outline-danger"
                                                  onClick={() => confirmRequestAction(travelRequest._id, requestConstants.Statuses.REJECTED)}
                                                >
                                                  Reject
                                                </div>{" "}
                                              </div>
                                            )}
                                          {
                                            (
                                              props.requestType === requestConstants.RequestTypes.SENT
                                            ) &&
                                            (
                                              // <div className="d-flex flex-column 1m-1 py-2 text-right">

                                              <div
                                                // value={travelRequest}
                                                className={"mb-2 p-2 badge " + (requestConstants.Statuses.ACCEPTED === travelRequest.status ? "badge-success" :
                                                  (requestConstants.Statuses.REJECTED === travelRequest.status ? "badge-danger" : "badge-warning text-dark"))}

                                              >
                                                {requestConstants.getStatusString(travelRequest.status)}
                                              </div>

                                            )
                                          }
                                        </div></div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                        {/* </div> */}
                      </div>
                    </div>
                  )}
                  {/* new table ends here */}
                </div>
              </div>
            )}
          </aside>
        </div>
      </div>
    </div>
  );
}
