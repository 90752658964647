import React from "react";
import PathConstants from "./PathConstants";
import Login from "../Components/user/Login";
import Register from "../Components/user/Register";
import Dashboard from "../Components/rides/Dashboard";
import CreateUpdateRideComponent from "../Components/rides/CreateUpdateRideComponent";
import BookRideComponent from "../Components/rider/BookRideComponent";
import BookingDetailComponent from "../Components/rider/BookingDetailComponent";

import RequireAuth from "../Components/Common/RequireAuth";
import RideDetailComponent from "../Components/rides/RideDetailComponent";
import Page403 from "../pages/Page403";
import SearchResults from "../Components/rides/SearchResults";
import Account from "../Components/user/UserAccount";
import UserAccount from "../Components/user/UserAccount";
import EmailVerify from "../Components/user/EmailVerify";
import EditProfile from "../Components/user/EditProfile";
import ForgotPassword from "../Components/user/ForgotPassword";
import RequireOperator from "../Components/Common/RequireOperator";
import RequireRider from "../Components/Common/RequireRider";
import ContactUs from "../pages/ContactUs";
import CoTravelSearchResults from "../Components/rides/CoTravelSearchResults";
import RequestConstants from "../constants/RequestConstants";
import CoTravelCreateQuery from "../Components/Common/SearchComponent/CoTravelCreateQuery";
import RequireAdmin from "../Components/Common/RequireAdmin";
import AdminPenal from "../Components/Admin/AdminPenal";
import UserProfile from "../Components/user/UserProfile";
import CoTravelQueryListComponent from "../Components/rides/CoTravelQueryListComponent";
import UserAccountSideBar from "../Components/user/UserAccountSideBar";
import TabConstants from "./TabConstants";
import CoTravelRequestList from "../Components/user/CoTravelRequestList";
import UserBookings from "../Components/user/UserBookings";
import DashboardOverview from "../Components/rides/DashboardOverview";
import DashboardSideBar from "../Components/rides/DashboardSideBar";
import DashboardRide from "../Components/rides/DashboardRide";
import DashboardVehicle from "../Components/rides/DashboardVehicle";
import DashboardDriver from "../Components/rides/DashboardDriver";
import DashboardBooking from "../Components/rides/DashboardBooking";
import OperatorProfile from "../Components/user/OperatorProfile";

const Home = React.lazy(() => import("../pages/Home"));
// const Team = React.lazy(() => import("../pages/Team"));
// const Portfolio = React.lazy(() => import("../pages/Portfolio"));
const About = React.lazy(() => import("../pages/About"));
const requestConstants = RequestConstants();
const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.LOGIN, element: <Login /> },
  { path: PathConstants.REGISTER, element: <Register /> },
  {
    path: PathConstants.DASHBOARD,
    element: (
      <RequireOperator>
        <DashboardSideBar activeTab={TabConstants.DASHBOARD_OVERVIEW} />
        <DashboardOverview />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.ACCOUNT,
    element: (
      <RequireRider>
        <UserAccount />
      </RequireRider>
    ),
  },

  {
    path: PathConstants.DASHBOARD_OVERVIEW,
    element: (
      <RequireOperator>
        <DashboardSideBar activeTab={TabConstants.DASHBOARD_OVERVIEW} />
        <DashboardOverview />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.DASHBOARD_RIDES,
    element: (
      <RequireOperator>
        <DashboardSideBar activeTab={TabConstants.DASHBOARD_RIDES} />
        <DashboardRide />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.DASHBOARD_BOOKINGS,
    element: (
      <RequireOperator>
        <DashboardSideBar activeTab={TabConstants.DASHBOARD_BOOKINGS} />
        <DashboardBooking />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.DASHBOARD_DRIVERS,
    element: (
      <RequireOperator>
        <DashboardSideBar activeTab={TabConstants.DASHBOARD_DRIVERS} />
        <DashboardDriver />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.DASHBOARD_VEHICLES,
    element: (
      <RequireOperator>
        <DashboardSideBar activeTab={TabConstants.DASHBOARD_VEHICLE} />
        <DashboardVehicle />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.DASHBOARD_PROFILE,
    element: (
      <RequireOperator>
        <DashboardSideBar activeTab={TabConstants.DASHBOARD_PROFILE} />
        {/* <UserProfile /> */}
        <OperatorProfile />
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.OPERATOR_PROFILE + "/:id",
    element: (
      <RequireAuth>
        <OperatorProfile />
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.ACCOUNT,
    element: (
      <RequireRider>
        <UserAccount />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.ACCOUNT_PROFILE,
    element: (
      <RequireRider>
        <UserAccountSideBar activeTab={TabConstants.PROFILE} />
        <UserProfile />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.ACCOUNT_BOOKING,
    element: (
      <RequireRider>
        <UserAccountSideBar activeTab={TabConstants.BOOKING} />
        <UserBookings />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.ACCOUNT_QUERIES,
    element: (
      <RequireRider>
        <UserAccountSideBar activeTab={TabConstants.TRAVEL_QUERY} />
        <CoTravelQueryListComponent isMine={true} />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.ACCOUNT_REQUEST_RECEIVED,
    element: (
      <RequireRider>
        <UserAccountSideBar activeTab={TabConstants.TRAVEL_REQUEST_RECEIVED} />
        <CoTravelRequestList requestType={requestConstants.RequestTypes.RECEIVED} />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.ACCOUNT_REQUEST_SENT,
    element: (
      <RequireRider>
        <UserAccountSideBar activeTab={TabConstants.TRAVEL_REQUEST_SENT} />
        <CoTravelRequestList requestType={requestConstants.RequestTypes.SENT} />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.USER_PROFILE + "/:pathParam",
    element: (
      <RequireRider>
        <UserProfile />
      </RequireRider>
    ),
  },
  {
    path: PathConstants.CREATE_RIDE,
    element: (
      <RequireOperator>
        {" "}
        <CreateUpdateRideComponent />{" "}
      </RequireOperator>
    ),
  },
  {
    path: PathConstants.BOOK_RIDE + "/:id",
    element: (
      <RequireAuth>
        {" "}
        <BookRideComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.RIDE_DETAIL + "/:id",
    element: (
      <RequireAuth>
        {" "}
        <RideDetailComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.RIDE_DETAIL,
    element: (
      <RequireAuth>
        {" "}
        <RideDetailComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.BOOKING_DETAIL + "/:id",
    element: (
      <RequireAuth>
        {" "}
        <BookingDetailComponent />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.SEARCH_RESULTS,
    element: (
      // <RequireAuth>
      // {" "}
      <SearchResults />
    ),
  },
  {
    path: PathConstants.COTRAVEL_SEARCH_RESULTS,
    element: (
      <RequireAuth>
        <CoTravelSearchResults />
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.COTRAVEL_CREATE_QUERY,
    element: (
      <RequireAuth>
        <CoTravelCreateQuery />
      </RequireAuth>
    ),
  },

  {
    path: PathConstants.EMAIL_VERIFY,
    element: (
      // <RequireAuth>
      // {" "}
      <EmailVerify />
      // { " "}
      // </RequireAuth>
    ),
  },
  {
    path: PathConstants.EDIT_PROFILE,
    element: (
      <RequireAuth>
        {" "}
        <EditProfile />{" "}
      </RequireAuth>
    ),
  },
  {
    path: PathConstants.ADMIN_PENAL,
    element: (
      <RequireAdmin>
        {" "}
        <AdminPenal />{" "}
      </RequireAdmin>
    ),
  },
  {
    path: PathConstants.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },

  { path: PathConstants.ABOUT, element: <About /> },
  { path: PathConstants.CONTACT, element: <ContactUs /> },
  { path: PathConstants.PAGE403, element: <Page403 /> },
];
export default routes;
