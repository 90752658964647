import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import Ride from "../../models/Ride";
import PathConstants from "../../routes/PathConstants";
import "../../styles/create-ride-form.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardService from "../../Services/DashboardService";
import DriverService from "../../Services/DriverService";
import { toast } from "react-toastify";
import FiltersConstants from "../../constants/FiltersConstants";

export default function CreateUpdateDriver(props) {
  const [inputs, setInputs] = useState({});
  const [validated, setValidated] = useState(false);
  const [otherVaidation, setOtherVaidation] = useState({
    isValidPhone: true,
  });
  const [loading, setLoading] = useState(false);
  const filtersConstants = FiltersConstants();
  const driverService = useService(DriverService);
  const createDriver = event => {
    const form = event.currentTarget;
    // console.log(form);
    if (form.checkValidity() === false) {
      // event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    } else {
      if (!isValidPhoneNumber(inputs.phone)) {
        // setOtherVaidation({ isValidPhone: false });
        setOtherVaidation(prevState => ({
          ...prevState,
          isValidPhone: false,
        }));
        toast.dismiss();
        toast.error("Invalid Phone Number!");
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        return;
      }
      setLoading(true);
      driverService.post(inputs).subscribe({
        next: response => {
          if (response.status) {
            var driver = response.result;
            if (driver) {
              toast.dismiss();
              toast.success("Driver Added");
              props.sendConfirmationToParent(true);

            }
          }
        },
        error: e => {
          toast.dismiss();
          toast.error(e.message);
          // setLoading(false);
        },
        complete: () => {
          setLoading(false);
        },
      });
    }
  };
  const handleChange = event => {
    const name = event.target.name;
    var value = event.target.value;

    if (name === "age") {
      value = parseInt(value);
    } else if (name === "phone") {
      if (!isValidPhoneNumber(value)) {
        setOtherVaidation(prevState => ({
          ...prevState,
          isValidPhone: false,
        }));
      } else {
        setOtherVaidation(prevState => ({
          ...prevState,
          isValidPhone: true,
        }));
      }
    }
    setInputs(values => ({ ...values, [name]: value }));
  };
  const isValidPhoneNumber = phoneNumber => {
    var phoneRegex = /^\d{10}$/;
    if (phoneNumber && phoneNumber.match(phoneRegex)) {
      console.log("phone valid");
      return true;
    } else return false;
  };
  const handleSubmit = event => {
    event.preventDefault();
    createDriver(event);
  };

  useEffect(() => { }, []);

  return (
    <div className="App create-ride-form">
      <div className="mask d-flex align-items-center h-100">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-lg-9 col-xl-6">
              <div className="my-3">
                <div className="card-body">
                  <Form
                    onSubmit={handleSubmit}
                    validated={validated}
                    noValidate
                  >
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="name"
                        maxLength={50}
                        placeholder="Driver Name"
                        value={inputs.name || ""}
                        onChange={handleChange}
                        required
                      />{" "}
                    </div>
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="phone"
                        placeholder="Phone Number"
                        value={inputs.phone || ""}
                        onChange={handleChange}
                        required
                        maxLength={10}
                        minLength={10}
                      />
                      {!otherVaidation.isValidPhone && (
                        <div className="text-left small text-danger">
                          Invalid Phone Number
                        </div>
                      )}
                    </div>

                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        name="age"
                        min="18"
                        max="100"
                        placeholder="Age"
                        value={inputs.age || ""}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <Form.Select
                      className="form-select form-select-lg"
                      aria-label="Default select example"
                      id="gender"
                      name="gender"
                      type="string"
                      value={inputs.gender || ""}
                      // onChange={handleSelectChangeDriver}
                      onChange={handleChange}
                      required
                    >
                      <option disabled value={""}>
                        Gender
                      </option>

                      <option
                        key={filtersConstants.GenderFilters.MALE}
                        value={filtersConstants.GenderFilters.MALE}
                      >
                        {filtersConstants.GenderFilters.MALE_STRING}
                      </option>
                      <option
                        key={filtersConstants.GenderFilters.FEMALE}
                        value={filtersConstants.GenderFilters.FEMALE}
                      >
                        {filtersConstants.GenderFilters.FEMALE_STRING}
                      </option>
                      <option
                        key={filtersConstants.GenderFilters.TRANS}
                        value={filtersConstants.GenderFilters.TRANS}
                      >
                        {filtersConstants.GenderFilters.TRANS_STRING}
                      </option>
                    </Form.Select>
                    {loading && (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    )}
                    {!loading &&
                      <input className="mt-3 btn btn-dark" type="submit" />
                    }
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
