import { Route, Routes } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import "../../styles/common.css";
import BookingService from "../../Services/BookingService";
import SearchFilters from "../Common/SearchFilters";
import InfiniteScroll from "react-infinite-scroll-component";
import { throwError } from "rxjs";
import PathConstants from "../../routes/PathConstants";
import BookingStatus from "../../constants/BookingStatus";

export default function ListBookingsComponent(props) {
  const { user } = AuthUser();
  const [result, setResult] = useState([]);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();
  const bookingService = useService(BookingService);
  const [sortBy, setSortBy] = useState();
  const [filterBy, setFilterBy] = useState();

  useEffect(() => {
    console.log(props);
    if (props?.rideId) {
      //load ride specific booking
      fetchBookings();
    } else if (props?.showMyBookings) {
      //if role operator
      //if user
      //if admin
    } else {
      //all booking (only admin can see)
    }
    // console.log(result);
    // console.log(reload);
    // fetchMoreData();
  }, [reload, props?.rideId]);

  const reloadMyBookings = () => {
    setResult([]);
    setCurrentPage(0);
    setLastPage(0);
    setReload(!reload); //above use effect will run on change of reload value
  };
  const fetchBookings = () => {
    var inputs = {
      // from: queryS?.from,
      // to: query?.to,
      // departure: query?.departure,
      page: currentPage + 1,
      size: 3,
      sort_by: sortBy,
    };
    if (props.rideId) {
      inputs.ride_id = props.rideId;
    }
    setLoading(true);
    // getBookingsOfARide;
    if (props.rideId) {
      // bookingService.fetchMyBookings(inputs).subscribe({
      bookingService.getBookingsOfARide(props.rideId, inputs).subscribe({
        next: response => {
          if (response.status !== 0) {
            setLastPage(response?.result?.last_page);
            var newList = result.concat(response.result.data);
            setResult(
              newList //.concat(Array.from(response.result.data)),
            );
            setCurrentPage(currentPage + 1);
          }
          setLoading(false);
          setLoadingPage(false);
        },
        error: err => {
          setResult(
            [] //.concat(Array.from(response.result.data)),
          );
          setLoading(false);
          return throwError(() => err);
        },
      });
    }
  };
  const fetchMoreData = () => {
    console.log("fetching...");
    setLoading(true);
    setLoadingPage(true);
    if (currentPage > lastPage) {
      setLoading(false);
      setLoadingPage(false);
      return;
    }
    fetchBookings();
  };

  const getBookingDetail = bookingId => {
    navigate(PathConstants.BOOKING_DETAIL + "/" + bookingId);
  };
  function handleSortByAction(sortBy) {
    console.log(sortBy);
    setSortBy(sortBy);
    reloadMyBookings();
  }
  function handleFilterByAction(filterBy) {
    console.log(filterBy);
    setFilterBy(filterBy);
    reloadMyBookings();
  }

  function ShowStatus(props) {
    console.log(props);
    var classString = "col ",
      status = "";
    if (props?.booking.status === BookingStatus.CONFIRMED) {
      classString += "text-success";
      status = BookingStatus.CONFIRMED_NAME;
    } else if (props?.booking.status === BookingStatus.CANCELLED) {
      classString += "text-danger";
      status = BookingStatus.CANCELLED_NAME;
    }

    return <strong className={classString}>{status}</strong>;
  }
  return (
    <div className="contaniner">
      <div className="row justify-content-center">
        <div className="border-top mt-2 px-2 col-10 col-md-8 ">
          <div className="mt-2 text-left">
            <button
              className="btn btn-sm btn-outline-dark text-left"
              onClick={reloadMyBookings}
            >
              Reload
            </button>
          </div>
          {lastPage === 0 && <div>Loading...</div>}
          {lastPage === 1 && result?.length <= 0 && (
            <strong className="text-danger">No Bookings</strong>
          )}
          {lastPage > 0 && result?.length > 0 && (
            <div>
              <strong className="text-center">Bookings1</strong>
              <div>
                {/* {loadingPage && <div>Loading...</div>} */}
                {true && (
                  <div className="common container mt-3">
                    <div className="row">
                      <aside className="px-0 col-12">
                        {/* //////////// */}
                        {!props?.rideId && (
                          <SearchFilters
                            sendSortByToParent={handleSortByAction}
                            sendFilterByToParent={handleFilterByAction}
                          ></SearchFilters>
                        )}
                        {/* ////////Sorting Drpdown Ends///////// */}
                        <div className="grid search-list">
                          <div className="grid-body">
                            {/* <!-- BEGIN SEARCH RESULT --> */}

                            <div className="row d-flex justify-content-center">
                              <div
                                className="col-11 col-sm-10 text-left table"
                                style={{ overflow: "hidden" }}
                              >
                                <InfiniteScroll
                                  dataLength={result?.length}
                                  next={fetchMoreData}
                                  hasMore={true}
                                  loader={loading && <h4>Loading...</h4>}
                                >
                                  {result.map(booking => {
                                    {
                                      return (
                                        <div
                                          className="mb-5 shadow card card-stepper bg-image hover-overlay shadow-1-strong"
                                          style={{ borderColor: "lawngreen" }}
                                          key={booking._id}
                                        >
                                          <div className="card-body p-1">
                                            <div
                                              className="px-2 btn btn-light py-2 col border rounded"
                                              onClick={event =>
                                                getBookingDetail(booking._id)
                                              }
                                            >
                                              <div className="d-flex flex-column flex-md-row justify-content-center">
                                                <div className="text-break col align-self-center text-wrap">
                                                  <div className="text-wrap text-capitalize">
                                                    {booking.pickup_loc?.city}
                                                  </div>
                                                  <div className="text-wrap text-muted text-capitalize">
                                                    {booking.pickup_loc?.state}
                                                  </div>
                                                </div>
                                                <div className="my-3 my-md-2 align-self-center col col-md-4 text-center text-wrap">
                                                  <div>
                                                    {booking?.passengers
                                                      ? booking.passengers
                                                        .length + " Pax"
                                                      : ""}
                                                  </div>
                                                  <hr className="my-0 flex-fill track-line" />
                                                </div>
                                                <div className="text-break col align-self-center text-wrap">
                                                  <div className="text-wrap text-capitalize">
                                                    {booking.drop_loc?.city}
                                                  </div>
                                                  <div className="text-wrap text-muted text-capitalize">
                                                    {booking.drop_loc?.state}
                                                  </div>
                                                </div>
                                              </div>
                                              <hr />
                                              <div className="d-flex justify-content-between">
                                                <div className="text-wrap col 1col-sm-3">
                                                  <small>
                                                    {new Date(
                                                      parseInt(
                                                        booking?.ride?.departure
                                                      )
                                                    ).toDateString()}
                                                  </small>
                                                </div>
                                                <div className="text-wrap col 1col-sm-3">
                                                  {/* <div className="row"> */}
                                                  <div className="d-flex flex-column flex-md-row">
                                                    <small className="col">
                                                      Status:{" "}
                                                    </small>
                                                    <ShowStatus
                                                      booking={booking}
                                                    ></ShowStatus>
                                                  </div>
                                                  {/* </div> */}
                                                </div>
                                              </div>
                                              <div className="text-break text-wrap d-flex mt-3 justify-content-between">
                                                <div className="border-right col text-left">
                                                  <small className="mr-2">
                                                    Booking Id:
                                                  </small>
                                                  <small>
                                                    {booking?.booking_id}
                                                  </small>
                                                </div>
                                                <div className="col text-left">
                                                  <small className="mr-2">
                                                    Booked On:
                                                  </small>
                                                  <small>
                                                    {new Date(
                                                      parseInt(
                                                        booking?.created_at
                                                      )
                                                    ).toDateString()}
                                                  </small>
                                                </div>
                                              </div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </InfiniteScroll>
                                {/* </div> */}
                              </div>
                            </div>
                            {/* new table ends here */}
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
