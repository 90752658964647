import { EMPTY, Observable } from "rxjs";
import { MessageContextProps } from "../contexts/MessageContext";
import ErrorHandling from "./ErrorHandling";
import IService from "./IService";

import api from "../config/Api";
import { catchError } from "rxjs/operators";
import { User } from "../models/User";
import AuthUser from "../Components/Common/AuthUser";
import Ride from "../models/Ride";
import { Driver } from "../models/Driver";

export default class DriverService extends ErrorHandling implements IService {
  constructor(context: MessageContextProps) {
    super(context);
  }

  get<Ride>(id: string): Observable<Ride[]> {
    return api.get<Ride[]>(`/rides/` + id).pipe(
      catchError((err: any) => {
        this.handleError("Ride error");
        console.log(err);
        return [];
      })
    );
  }

  getDrivers(filters?: object): Observable<void> {
    return api.get<void>(`/drivers`, undefined, filters).pipe(
      catchError((err: any) => {
        this.handleError("Driver dashboard error");
        console.log(err);
        return [];
      })
    );
  }

  getMyDrivers(): Observable<void> {
    return api.get<void>(`/drivers/mine`).pipe(
      catchError((err: any) => {
        this.handleError("Driver dashboard error");
        console.log(err);
        return [];
      })
    );
  }
  createDriver(obj: object): Observable<Ride> {
    console.log(obj);
    let response = api.post<any>(`/drivers`, obj).pipe(
      catchError((err: any) => {
        this.handleError("Driver error");
        // console.log(err);
        return err;
      })
    );
    // console.log(response);
    return response;
  }
  // post<Ride>(arg: Ride): Observable<Ride[]> {
  post<Driver>(obj: object): Observable<Driver> {
    return api
      .post<any>(`/drivers`, obj)
      .pipe
      // catchError((err: any) => {
      // this.handleError("Ride error");
      //   // console.log(err);
      // return err;
      // })
      ();
  }
  patch<T>(arg: T): Observable<T[]> {
    throw new Error("Method not implemented.");
  }

  delete(id: string): Observable<any> {
    // console.log(id);
    return api.delete<any>(`/drivers`, id).pipe(
      catchError((err: any) => {
        this.handleError("Driver error");
        // console.log(err);
        throw err;
      })
    );
  }
}
