import AuthUser from "../Common/AuthUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import UserProfile from "./UserProfile";
import UserBookings from "./UserBookings";
import RolesConstants from "../../constants/RolesConstants";
import CoTravelQueryListComponent from "../rides/CoTravelQueryListComponent";
import CoTravelRequestList from "./CoTravelRequestList";
import RequestConstants from "../../constants/RequestConstants";
import PathConstants from "../../routes/PathConstants";
import TabConstants from "../../routes/TabConstants";

export default function UserAccountSideBar(props) {
  const { user } = AuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [showElement, setShowElement] = useState(props.activeTab ? props.activeTab : TabConstants.PROFILE);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const dashboardService = useService(DashboardService);

  const loadProfile = () => {
    handleClose();
    setShowElement(TabConstants.PROFILE);
    navigate(PathConstants.ACCOUNT_PROFILE);
  };

  const loadBookings = () => {
    handleClose();
    setShowElement(TabConstants.BOOKING);
    if (user?.role === RolesConstants.RIDER) {
      navigate(PathConstants.ACCOUNT_BOOKING);
    }
  };
  const loadTravelQueries = () => {
    handleClose();
    setShowElement(TabConstants.TRAVEL_QUERY);
    if (user?.role !== RolesConstants.OPERATOR) {
      navigate(PathConstants.ACCOUNT_QUERIES);
    }
  };
  const loadTravelRequests = (type) => {
    handleClose();
    setShowElement(type);
    if (type === TabConstants.TRAVEL_REQUEST_RECEIVED
      && user?.role !== RolesConstants.OPERATOR) {
      navigate(PathConstants.ACCOUNT_REQUEST_RECEIVED);
    }
    else if (type === TabConstants.TRAVEL_REQUEST_SENT
      && user?.role !== RolesConstants.OPERATOR
    ) {
      navigate(PathConstants.ACCOUNT_REQUEST_SENT);
    }
  };
  return (
    <div className="container1" id="main">
      <Offcanvas className="bg-dark" show={show} onHide={handleClose}>
        <Offcanvas.Header className="text-dark bg-warning1 app-bg-color" closeButton>
          <Offcanvas.Title className="1text-dark">My Account</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="nav flex-column sticky-top pl-0 mt-3">
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.PROFILE ? "nav-link text-primary" : "nav-link"
                }
                onClick={loadProfile}
                href="#"
              >
                Profile
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.BOOKING ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={loadBookings}
              >
                My Bookings
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.TRAVEL_QUERY ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={loadTravelQueries}
              >
                My Ride Requests
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.TRAVEL_REQUEST_SENT ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadTravelRequests(TabConstants.TRAVEL_REQUEST_SENT)}
              >
                Travel Requests Sent
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  showElement === TabConstants.TRAVEL_REQUEST_RECEIVED ? "nav-link text-primary" : "nav-link"
                }
                href="#"
                onClick={() => loadTravelRequests(TabConstants.TRAVEL_REQUEST_RECEIVED)}
              >
                Travel Requests Rcvd
              </a>
            </li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="rounded-0 btn btn-dark" onClick={handleShow}>
        {">"}
      </div>
    </div>
  );
}
