import { useState } from "react";
import AuthUser from "../Common/AuthUser";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PathConstants from "../../routes/PathConstants";
import { useEffect } from "react";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import api from "../../config/Api";
import Form from "react-bootstrap/Form";
import "../../styles/login.css";
import RolesConstants from "../../constants/RolesConstants";
import AuthService from "../../Services/AuthService";
// Importing toastify module
import { toast } from "react-toastify";

// import "react-toastify/dist/ReactToastify.css";

export default function Login() {
  // toast.configure();
  const navigate = useNavigate();
  const { setToken } = AuthUser();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [validated, setValidated] = useState(false);
  const { getToken } = AuthUser();
  const authService = useService(AuthService);
  const [loading, setLoading] = useState(false);
  const { state: locationState } = useLocation();
  const { redirectTo } = locationState ? locationState : [];
  useEffect(() => {
    //redirect to dashboard if /login is hit but user is already logged in
    // console.log(state);
    // console.log(redirectTo);
    if (getToken()) {
      // console.log(redirectTo);
      //   navigate(PathConstants.DASHBOARD, { replace: true });
      //   return;
    }
  });
  const submitLogin = event => {
    setLoading(true);
    // console.log("Login via email: " + email + " and password " + password);
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    } else {
      authService.login(email, password).subscribe({
        next: res => {
          // console.log(res);
          if (res.status !== 0) {
            toast.dismiss();
            toast.success("Logged in successfully!");

            // console.log(res);
            setToken(res.result.user, res.result.session.access_token);
            // rideService.setToken(res.result.user, res.result.session.access_token);
            //if role owner then navigate to dashboard
            // navigate(0);
            if (redirectTo) {
              navigate(`${redirectTo.pathname}${redirectTo.search}`);
            } else if (res.result.user.role === RolesConstants.OPERATOR) {
              // console.log(res.result.user.role);
              // if (redirectTo) {
              //   navigate(`${redirectTo.pathname}${redirectTo.search}`);
              // } else {
              navigate(PathConstants.DASHBOARD);
              // }
            } else {
              navigate(PathConstants.HOME);
              // window.location.reload();
            }
            navigate(0);
          }
          setLoading(false);
        },
        error: err => {
          setLoading(false);
          toast.dismiss();
          toast.error(err.message);
        },
      });
    }
  };

  const verifyEmail = () => {
    navigate(PathConstants.EMAIL_VERIFY, {
      // state: email,
      replace: true,
    });
  };
  return (
    <section
      className="vh-100 bg-image login1"
      style={{
        backgroundImage: `url(
          "https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp"
        )`,
      }}
    >
      {/* <ToastContainer /> */}
      <div className="mask d-flex align-items-center h-100 webkit-gradient">
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="card" style={{ borderRadius: 15 }}>
                <div className="card-body p-5">
                  <button
                    className="btn btn-outline-dark"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <h2 className="text-uppercase text-center mb-5">Login</h2>

                  <Form
                    validated={validated}
                    // className="needs-validation"
                    onSubmit={submitLogin}
                    noValidate
                  >
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="email"
                        id="form3Example3cg"
                        className="form-control form-control-lg"
                        placeholder="Email Id"
                        autoComplete="username"
                        onChange={e => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div data-mdb-input-init className="form-outline mb-4">
                      <input
                        type="password"
                        id="form3Example4cg"
                        className="form-control form-control-lg"
                        placeholder="Password"
                        autoComplete="current-password"
                        onChange={e => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    {/* <div className="checkbox">
                      <label>
                        <input type="checkbox" /> Remember me
                      </label>
                    </div> */}
                    <div className="d-flex justify-content-center">
                      <button
                        // type="button"

                        type="submit"
                        data-mdb-button-init
                        data-mdb-ripple-init
                        disabled={loading}
                        className="btn btn-block btn-lg app-bg-color text-body"
                      >
                        {loading ? "Logging In..." : "Login"}
                        {loading && (
                          <span className="spinner-border" role="status"></span>
                        )}
                      </button>
                    </div>
                    <div className="px-0 col pt-3">
                      <Link
                        className=""
                        to={PathConstants.FORGOT_PASSWORD}
                      // onClick={() => setOpenBasic(!openBasic)}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="px-0 col pt-3">
                      <Link
                        className=""
                        // to={PathConstants.FORGOT_PASSWORD}
                        onClick={() => verifyEmail()}
                      >
                        Verify Email
                      </Link>
                    </div>
                    <p className="text-center text-muted mt-5 mb-0">
                      Do not have an account?{" "}
                      <a href="/register" className="fw-bold text-body">
                        <u>Register here</u>
                      </a>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
