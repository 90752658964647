import { useEffect, useState } from "react";

import useService from "../../hooks/useService";
import AdminService from "../../Services/AdminService";
import { toast } from "react-toastify";
import CreateUpdateRideComponent from "../rides/CreateUpdateRideComponent";

export default function RidesPenal() {
  const [rideList, setRideList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRideId, setSelectedRideId] = useState();
  const [reloadEditForm, setReloadEditForm] = useState(false);
  const adminService = useService(AdminService);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchRides();
  }, [reload]);

  const fetchRides = () => {
    setLoading(true);
    setShowEdit(false);
    adminService.fetchRides().subscribe({
      next: response => {
        if (response?.status === 0) {
          toast.error(response?.message);
        } else {
          toast.dismiss();
          toast.success(response?.message);
          setRideList(response.result.data);
        }
        setLoading(false);
      },
      error: err => {
        setLoading(false);
        toast.dismiss();
        toast.error(err?.message);
      },
    });
  };

  const editRide = (ride) => {
    setReloadEditForm(!reloadEditForm);
    setSelectedRideId(ride._id);
    setShowEdit(true);
  };
  function getFrom(ride) {
    let from = "";
    if (ride.from_loc?.landmark) {
      from += ride.from_loc?.landmark + ", ";
    }
    if (ride.from_loc?.city) {
      from += ride.from_loc?.city + ", ";
    }
    if (ride.from_loc?.state) {
      from += ride.from_loc?.state;
    }
    return from;
  }
  function getTo(ride) {
    let to = "";
    if (ride.to_loc?.landmark) {
      to += ride.from_loc?.landmark + ", ";
    }
    if (ride.to_loc?.city) {
      to += ride.to_loc?.city + ", ";
    }
    if (ride.to_loc?.state) {
      to += ride.to_loc?.state;
    }
    return to;
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="mt-2 px-0">
          <button
            className="m-2 btn btn-outline-dark"
            onClick={fetchRides}
          >
            Reload
          </button>
          <button
            className="m-2 btn btn-outline-dark"
            onClick={ride => editRide(ride)}
          >
            Edit Ride
          </button>
          {loading && (
            <div className="my-5 text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {!loading &&
            <div className="table-responsive">

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Departure</th>
                    <th scope="col">Arrival</th>
                    <th scope="col">Fare</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Status</th>
                    <th scope="col">Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {rideList.map((ride, length = 0) => {
                    return (

                      <tr key={ride._id}>
                        <th scope="row">{length + 1}</th>
                        <td>{getFrom(ride)}</td>
                        <td>{getTo(ride)}</td>
                        <td>{new Date(
                          parseInt(ride.departure)
                        ).toDateString()}
                        </td>
                        <td>{new Date(
                          parseInt(ride.arrival)
                        ).toDateString()}
                        </td>
                        <td>{ride.fare}
                        </td>
                        <td>{new Date(
                          parseInt(ride.created_at)
                        ).toDateString()}
                        </td>
                        <td>
                          {ride.status ? ride.status : "Pending"}
                        </td>
                        <td>
                          <div className="btn btn-sm btn-primary" onClick={() => editRide(ride)}>Edit</div>
                        </td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
          }

          {showEdit &&
            <CreateUpdateRideComponent id={selectedRideId} reload={reloadEditForm}></CreateUpdateRideComponent>
          }
        </div>
      </div>
    </div>
  );
}
