const TabConstants = {
  PROFILE: "P",
  BOOKING: "B",
  TRAVEL_QUERY: "Q",
  TRAVEL_REQUEST_SENT: "S",
  TRAVEL_REQUEST_RECEIVED: "R",

  DASHBOARD_OVERVIEW: "DO",
  DASHBOARD_RIDE: "DR",
  DASHBOARD_BOOKINGS: "DB",
  DASHBOARD_DRIVER: "DD",
  DASHBOARD_VEHICLE: "DV",
  DASHBOARD_PROFILE: "DP",

};
export default TabConstants;
