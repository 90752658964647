const PathConstants = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  DASHBOARD: "/dashboard", //for driver/operator
  ACCOUNT: "/account", //for user
  ACCOUNT_PROFILE: "/account/profile", //for user
  ACCOUNT_BOOKING: "/account/booking", //for user
  ACCOUNT_QUERIES: "/account/queries", //for user
  ACCOUNT_REQUEST_RECEIVED: "/account/requests/received", //for user
  ACCOUNT_REQUEST_SENT: "/account/requests/sent", //for user
  CREATE_RIDE: "/addride",
  BOOK_RIDE: "/book/ride",
  BOOKING_DETAIL: "/booking-detail",
  RIDE_DETAIL: "/trip-detail",
  SEARCH_RESULTS: "/search",
  USER_PROFILE: "/user/profile",
  USER_BOOKINGS: "/user/bookings",
  EDIT_PROFILE: "/profile/update",
  ABOUT: "/about",
  PAGE403: "/unauth",
  PAGE404: "/page404",
  EMAIL_VERIFY: "/email/verify",
  FORGOT_PASSWORD: "/password/forgot",
  CONTACT: "/contactus",

  COTRAVEL_SEARCH_RESULTS: "/cotravel/search",
  COTRAVEL_CREATE_QUERY: "/cotravel/query",

  ADMIN_PENAL: "/penal/admin",

  //Operator Account
  DASHBOARD_OVERVIEW: "/dashboard/overview",
  DASHBOARD_RIDES: "/dashboard/rides",
  DASHBOARD_BOOKINGS: "/dashboard/bookings",
  DASHBOARD_DRIVERS: "/dashboard/drivers",
  DASHBOARD_VEHICLES: "/dashboard/vehicles",
  DASHBOARD_PROFILE: "/dashboard/profile",

  //Operator
  OPERATOR_PROFILE: "/operator/profile",
};

export default PathConstants;
