import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./list-rides.css";
import PathConstants from "../../routes/PathConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import useService from "../../hooks/useService";
import { throwError } from "rxjs";
import FiltersConstants from "../../constants/FiltersConstants";
import CoTravelService from "../../Services/CoTravelService";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import { toast } from "react-toastify";

export default function CoTravelQueryListComponent(queryList) {
  const filtersConstants = FiltersConstants();
  const [list, setList] = useState([]);
  const [inputsParams, setInputsParams] = useState({});
  const coTravelService = useService(CoTravelService);
  const [currentPage, setCurrentPage] = useState(1);
  // const [sortBy, setSortBy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalQueries, setTotalQueries] = useState(0);
  // const [filterBy, setFilterBy] = useState();
  // const [reload, setReload] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [fetchNewData, setFetchNewData] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });
  const [selectedQuery, setSelectedQuery] = useState(false);
  const [nearby, setNearby] = useState(0);
  const navigate = useNavigate();

  const initialiseItems = (queries) => {
    setList(queries?.data);
    setCurrentPage(queries?.current_page);
    setLastPage(queries?.last_page);

    setTotalQueries(queries?.total);
    // setHasLoaded(true);
  }
  useEffect(() => {
    if (!fetchNewData && !queryList?.isMine && queryList?.queryList?.queries?.data) {
      initialiseItems(queryList?.queryList?.queries);
      setInputsParams(queryList?.queryList?.inputs);
      if (queryList?.queryList?.inputs?.nearby) {
        setNearby(1);
      }
      setHasLoaded(true);
    } else if (queryList.isMine) {
      setHasLoaded(true);
      fetchMoreData();
    }
    else {
      fetchMoreData();
    }
  }, []);

  const fetchMoreData = () => {
    if (hasLoaded || queryList.isMine) {
      if (currentPage >= lastPage) {
        // setLoading(false);
        return;
      }

      if (queryList.isMine) {
        getMyQueries(1);
      } else {
        searchQueries(1);
      }
    }
  };
  const getPrevious = (event) => {
    event.preventDefault();
    setPageAndGetQueries(currentPage - 1);
  };
  const getNext = (event) => {
    event.preventDefault();
    if (currentPage >= lastPage) return;
    setPageAndGetQueries(currentPage + 1);
  }

  const setPageAndGetQueries = (selectedPage) => {
    if (queryList.isMine) {
      getMyQueries(selectedPage);
    } else {
      searchQueries(selectedPage);
    }
  };
  const getMyQueries = (selectedPage) => {
    setLoading(true);
    coTravelService
      .myQueries({
        page: selectedPage,
        // sort_by: sortBy,
        // filter_by: filterBy,
      })
      // .search({ from: from, to: to, date: date })
      .subscribe({
        next: response => {
          if (response.status) {
            // var newList = list.concat(response.result.data);
            var newList = response.result.data;
            setList(
              newList //.concat(Array.from(response.result.data)),
            );
            setCurrentPage(response?.result?.current_page);
            setLastPage(response?.result?.last_page);
            setTotalQueries(response?.result?.total);
          }
          setLoading(false);
        },
        error: err => {
          // alert("network error");
          setLoading(false);
          setList(
            [] //.concat(Array.from(response.result.data)),
          );

          return throwError(() => err);
        },
      });
  };
  const searchQueries = (selectedPage) => {
    setLoading(true);
    coTravelService
      .search({
        from: inputsParams?.from,
        to: inputsParams?.to,
        date: inputsParams?.date,
        page: selectedPage,
        // sort_by: sortBy,
        // filter_by: filterBy,
        nearby: nearby,
        paxcount: inputsParams?.paxcount,
      })
      // .search({ from: from, to: to, date: date })
      .subscribe({
        next: response => {
          if (response.status) {
            // var newList = list.concat(response.result.data);
            var newList = response.result.data;
            setList(
              newList //.concat(Array.from(response.result.data)),
            );
            setCurrentPage(response?.result?.current_page);
            setLastPage(response?.result?.last_page);
          }
          setLoading(false);
        },
        error: err => {
          // alert("network error");
          setLoading(false);
          setList(
            [] //.concat(Array.from(response.result.data)),
          );

          return throwError(() => err);
        },
      });
  };

  const AgeSexString = (travelQuery) => {
    let str = "";
    travelQuery = travelQuery?.travelQuery;
    if (travelQuery?.createdby?.profile?.age) {
      str += travelQuery.createdby.profile.age;
      if (travelQuery?.createdby?.profile?.gender) {
        str += " | " + travelQuery.createdby.profile.gender;
      }
    } else if (travelQuery?.createdby?.profile?.gender) {
      str += travelQuery.createdby.profile.gender;
    }
    return <div>{str}</div>;
  }
  const makeRequest = () => {
    coTravelService
      .makeRequest({
        // requested_by: selectedQuery?.requested_by,
        requested_to: selectedQuery?.createdby?._id,
        query_id: selectedQuery?._id,
      })
      .subscribe({
        next: response => {
          if (response.status) {
            toast.success(response.message);
            navigate(PathConstants.ACCOUNT_REQUEST_SENT);
          }
        },
        error: e => toast.error(e),
        // complete: () => console.info("complete"),
      });
  }
  const confirmAddRequest = (travelQuery) => {
    setOpenConfirmationDialog(true);
    setSelectedQuery(travelQuery);
    var title = "Make Co-Travel Request";

    var body =
      "Do you want to send the request? Recipient will be notified that you are interested.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));

  };
  const navigateToNewQueryPage = () => {
    navigate(PathConstants.COTRAVEL_CREATE_QUERY);
  }
  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    if (confirmation) {
      makeRequest(selectedQuery);
    }
  }
  const showUserProfile = (travelQuery) => {
    var userId = travelQuery.created_by;
    navigate(PathConstants.USER_PROFILE + "/" + userId);
    return;
  }
  return (
    <div>
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        // close={closeDialog}
        // confirm={confirmDialog}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      <div className="search-results container mt-3">
        <div className="row justify-content-center">
          <aside className="col-lg-8">
            {/* //////////// */}
            {/* <SearchFilters
              sendSortByToParent={handleSortByAction}
              sendFilterByToParent={handleFilterByAction}
            ></SearchFilters> */}
            {/* ////////Sorting Drpdown Ends///////// */}
            {hasLoaded && (
              <div className="grid search-list">
                <div className="grid-body">
                  {/* <div className="row"> */}
                  {/* <!-- BEGIN SEARCH RESULT --> */}
                  {totalQueries <= 0 && <strong>
                    <div className="1mt-5 text-center text-danger">No match found</div>
                    <div className="small text-center text-danger">You can raise own query so that others can connect with you.</div>
                  </strong>}
                  {!queryList?.queryList?.inputs?.nearby && <div className="text-small text-center mb-4">
                    {/* <div className="text-danger">
                        {"Raise your travel query so that others can connect with you"}
                      </div> */}
                    <div className="btn btn-outline-success" onClick={navigateToNewQueryPage}>Raise Travel Query</div>
                  </div>}

                  {!queryList?.queryList?.inputs?.nearby && totalQueries > 0 && <div className="text-danger">
                    {totalQueries}
                    {queryList.isMine && " raised by you"}
                    {!queryList.isMine && " found on " +
                      new Date(inputsParams.date).toLocaleDateString()}
                    {" - " + lastPage + " Pages"}
                  </div>
                  }
                  {queryList?.queryList?.inputs?.nearby && totalQueries > 0 && <div className="text-danger">
                    Other travellers you might want to join
                    {" - " + lastPage + " Pages"}
                  </div>
                  }
                  {totalQueries > 0 && (
                    <div className="row d-flex justify-content-center">
                      <div
                        className="col-10 text-left table"
                        style={{ overflow: "hidden" }}
                      >
                        {/* <div className="table-responsive"> */}
                        {/* <strong>Ride Details</strong> */}
                        {/* <InfiniteScroll
                          dataLength={list?.length}
                          next={fetchMoreData}
                          hasMore={true}
                          loader={
                            loading && (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              </div>
                            )
                          }
                        > */}
                        {list.map(travelQuery => {
                          return (
                            <div
                              className="shadow mb-3 card card-stepper bg-image hover-overlay"
                              style={{
                                borderColor: "lawngreen",
                                height: "auto", overflow: "auto"
                              }}
                              key={travelQuery._id}
                            >
                              <div className="card-body p-2">
                                <div className="d-flex flex-column flex-md-row">
                                  {!queryList.isMine && <div className="px-0 pb-2 col-auto justify-content-between 1border d-flex flex-row border-bottom 1rounded">

                                    <div className="d-flex flex-row flex-md-column 1col-4">
                                      <a href="#" className="col-auto 1mr-2" onClick={() => showUserProfile(travelQuery)}><img
                                        src="/avatar.jpg"
                                        alt=""
                                        style={{ width: "30px", height: "30px" }}
                                        className="rounded-circle"
                                      />
                                      </a>
                                      <div className="col d-flex flex-column"><div className="1py-2">{travelQuery?.createdby?.name}
                                      </div>
                                        <AgeSexString travelQuery={travelQuery}></AgeSexString>
                                        {/* <div>{(travelQuery) => getAgeSexString(travelQuery)}
                                          </div> */}
                                      </div>
                                    </div>
                                    <div className="text-right col d-flex flex-column">
                                      {queryList?.queryList?.inputs?.nearby && <div className=""><small className="text-success">On {new Date(travelQuery.date).toLocaleDateString()}
                                      </small></div>}
                                      {!queryList.isMine && <div className=""><small className="badge badge-warning text-dark">{filtersConstants.getTimeSlotString(travelQuery.slot)}
                                      </small></div>}
                                    </div>
                                    {/* <div className="col text-left"><div className="text-danger font-weight-bold">Along with
                                      </div><div><span className="pr-2 text-success">1 Male
                                      </span><span className="pr-2 text-primary">1 Female
                                          </span><span className="pr-2 text-warning">1 Child
                                          </span></div></div> */}

                                  </div>}
                                  <div className="px-0 pb-2 col justify-content-between 1border d-flex flex-row border-bottom 1rounded">
                                    <div className="col px-0 justify-content-center 1border d-flex flex-row 1border-bottom 1rounded">
                                      <div className="p-2 col text-break 1col-auto d-flex flex-column text-center">
                                        <div className="text-left text-capitalize">
                                          {travelQuery.from_loc?.city === travelQuery.to_loc?.city && travelQuery.from_loc?.landmark ? travelQuery.from_loc?.landmark : travelQuery.from_loc?.city}
                                        </div>
                                        <div className="text-left text-capitalize text-muted small">
                                          {travelQuery.from_loc?.city === travelQuery.to_loc?.city ? travelQuery.from_loc?.city : travelQuery.from_loc?.state}
                                        </div>
                                      </div>

                                      <div className="p-2 col text-break 1col-auto d-flex flex-column text-center">
                                        <div className="text-left text-capitalize">
                                          {travelQuery.from_loc?.city === travelQuery.to_loc?.city && travelQuery.to_loc?.landmark ? travelQuery.to_loc?.landmark : travelQuery.to_loc?.city}
                                        </div>
                                        <div className="text-left text-capitalize text-muted small">
                                          {travelQuery.from_loc?.city === travelQuery.to_loc?.city ? travelQuery.to_loc?.city : travelQuery.to_loc?.state}
                                        </div>
                                      </div>

                                    </div>
                                    {!queryList.isMine && <div className="align-self-center border-left col-4 col-md-4 1d-flex 1flex-row text-left ">
                                      <div className="1d-flex flex-column 1m-1 py-2 text-center">

                                        <div
                                          className="mb-2 btn btn-outline-success"
                                          onClick={() => confirmAddRequest(travelQuery)}
                                        >
                                          Connect
                                        </div>{" "}
                                      </div>
                                    </div>}
                                    {queryList.isMine && <div className="align-self-center border-left col-4 col-md-4 1d-flex 1flex-row text-left ">
                                      <div className="1d-flex flex-column 1m-1 py-2 text-center">

                                        On
                                        <div
                                          className="mb-2 text-success"

                                        >
                                          {new Date(travelQuery.date).toDateString()}
                                        </div>
                                        <div className="mb-2 text-warning">{filtersConstants.getTimeSlotString(travelQuery.slot)}
                                        </div>{" "}
                                      </div>
                                    </div>}
                                  </div>
                                </div>

                              </div>
                            </div>
                          );
                        })}
                        {/* </InfiniteScroll> */}
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center">
                            <li className={"page-item" + (currentPage <= 1 ? " disabled" : "")}>
                              <a className="page-link" href="#" tabIndex="-1" onClick={getPrevious} aria-disabled={currentPage <= 1}>Previous</a>
                            </li>
                            {/* {<li className="page-item"><a className="page-link" href="#" onClick={() => setPageAndGetQueries(1)}>{currentPage - 1}</a></li>} */}
                            <li className="page-item active"><a className="page-link" href="#">{currentPage}</a></li>
                            {/* <li className="page-item"><a className="page-link" href="#" onClick={() => setPageAndGetQueries(1)}>{currentPage - 1}</a></li> */}
                            <li className={"page-item" + (currentPage >= lastPage ? " disabled" : "")}>
                              <a className="page-link" onClick={getNext} href="#">Next</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  )}
                  {/* new table ends here */}
                </div>
              </div>
            )}
          </aside>
        </div>
      </div>
    </div>
  );
}
