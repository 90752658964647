import { Route, Routes } from "react-router-dom";
import AuthUser from "../Common/AuthUser";
import { useNavigate } from "react-router-dom";
import useService from "../../hooks/useService";
import { useEffect, useState } from "react";
import "../../styles/common.css";
import BookingService from "../../Services/BookingService";
import SearchFilters from "../Common/SearchFilters";
import InfiniteScroll from "react-infinite-scroll-component";
import { throwError } from "rxjs";
import PathConstants from "../../routes/PathConstants";
import BookingStatus from "../../constants/BookingStatus";

export default function UserBookings(props) {
  const { user } = AuthUser();
  const [result, setResult] = useState([]);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const navigate = useNavigate();
  const bookingService = useService(BookingService);
  const [sortBy, setSortBy] = useState();
  const [filterBy, setFilterBy] = useState();

  // useEffect(() => {
  //   console.log(result);
  //   fetchMoreData();
  // }, []);

  useEffect(() => {
    console.log(result);
    console.log(reload);
    fetchMoreData();
  }, [reload]);

  const reloadMyBookings = () => {
    setResult([]);
    setCurrentPage(0);
    setLastPage(0);
    setReload(!reload); //above use effect will run on change of reload value
  };
  const fetchMyBookings = () => {
    console.log(sortBy);
    console.log(currentPage);
    var inputs = {
      // from: queryS?.from,
      // to: query?.to,
      // departure: query?.departure,
      page: currentPage + 1,
      size: 3,
      sort_by: sortBy,
    };
    if (props.rideId) {
      inputs.ride_id = props.rideId;
    }
    bookingService.fetchMyBookings(inputs).subscribe({
      next: response => {
        if (response.status) {
          // setResult(response?.result?.data);
          setLastPage(response?.result?.last_page);
          var newList = result.concat(response.result.data);
          setResult(
            newList //.concat(Array.from(response.result.data)),
          );
          setCurrentPage(currentPage + 1);
        }
        setLoading(false);
        setLoadingPage(false);
      },
      error: err => {
        setResult(
          [] //.concat(Array.from(response.result.data)),
        );
        setLoading(false);
        return throwError(() => err);
      },
    });
  };
  const fetchMoreData = () => {
    console.log("fetching...");
    setLoading(true);
    setLoadingPage(true);
    if (currentPage > lastPage) {
      setLoading(false);
      setLoadingPage(false);
      return;
    }
    fetchMyBookings();
  };

  const getBookingDetail = bookingId => {
    navigate(PathConstants.BOOKING_DETAIL + "/" + bookingId);
  };
  function handleSortByAction(sortBy) {
    // console.log(sortBy);
    setSortBy(sortBy);
    reloadMyBookings();
  }
  function handleFilterByAction(filterBy) {
    console.log(filterBy);
    setFilterBy(filterBy);
    reloadMyBookings();
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="mt-2 px-2 col col-md-8 ">
          <button className="btn btn-light border" onClick={reloadMyBookings}>
            Reload
          </button>
          <div>
            {/* {loadingPage && <div>Loading...</div>} */}
            {true && (
              <div className="common container mt-3">
                <div className="row">
                  <aside className="px-0 col-12 col-lg-8">
                    {/* //////////// */}
                    <SearchFilters
                      currentSortBy={sortBy}
                      sendSortByToParent={handleSortByAction}
                      sendFilterByToParent={handleFilterByAction}
                    ></SearchFilters>
                    {/* ////////Sorting Drpdown Ends///////// */}
                    <div className="grid search-list">
                      <div className="grid-body">
                        {/* <!-- BEGIN SEARCH RESULT --> */}

                        <div className="row d-flex justify-content-center">
                          <div
                            className="col col-sm-10 text-left table"
                            style={{ overflow: "hidden" }}
                          >
                            <InfiniteScroll
                              dataLength={result?.length}
                              next={fetchMoreData}
                              hasMore={true}
                              loader={loading && <h4>Loading...</h4>}
                            >
                              {result.map(booking => {
                                {
                                  return (
                                    <div
                                      className="mb-5 shadow card card-stepper bg-image hover-overlay shadow-1-strong"
                                      style={{ borderColor: "lawngreen" }}
                                      key={booking._id}
                                    >
                                      <div className="card-body p-1">
                                        <div
                                          className="px-2 btn btn-light py-2 col border rounded"
                                          onClick={event =>
                                            getBookingDetail(booking._id)
                                          }
                                        >
                                          <div className="d-flex mt-3 justify-content-between">
                                            <div className="px-1 col col-md-4 text-center align-self-center">
                                              <div className="text-break text-capitalize">
                                                {booking?.pickup_loc?.city}
                                              </div>
                                            </div>
                                            <div className="px-1 col-3 text-center align-self-center">
                                              <div>
                                                {booking?.passengers
                                                  ? booking.passengers.length +
                                                  " Pax"
                                                  : ""}
                                              </div>
                                              <hr className="my-0 flex-fill track-line" />
                                            </div>
                                            <div className="px-1 col col-md-4 text-center align-self-center">
                                              <div className="text-break text-capitalize">
                                                {booking?.drop_loc?.city}
                                              </div>
                                              {/* {booking.arrival && (
                                            <small>
                                              {new Date(
                                                parseInt(booking.arrival)
                                              ).toLocaleTimeString()}
                                            </small>
                                          )} */}
                                            </div>
                                          </div>
                                          <hr />
                                          <div className="text-wrap d-flex mt-3 justify-content-between">
                                            <div className="px-1 col d-flex flex-column text-left">

                                              <strong>
                                                {new Date(
                                                  booking?.ride?.departure
                                                ).toDateString() +
                                                  " " +
                                                  new Date(
                                                    booking?.ride?.departure
                                                  ).toLocaleTimeString("en-US", {
                                                    hour: "2-digit",
                                                    hour12: true, // Delete for 24-hour format
                                                    minute: "2-digit",
                                                  })}
                                              </strong>
                                            </div>
                                            <div className="px-1 col 1col-sm-3 text-right">
                                              {/* <div className="row"> */}
                                              <div className="col p-1">
                                                <small>Status: </small>
                                                <strong>
                                                  {booking.status ===
                                                    BookingStatus.CONFIRMED
                                                    ? BookingStatus.CONFIRMED_NAME
                                                    : BookingStatus.CANCELLED_NAME}
                                                </strong>
                                              </div>
                                              {/* </div> */}
                                            </div>
                                          </div>
                                          <hr />
                                          <div className="d-flex mt-3 justify-content-between">
                                            <div className="col px-1 text-left">
                                              <small className="mr-2">
                                                Booked On:
                                              </small>
                                              <small>
                                                {new Date(
                                                  parseInt(booking?.created_at)
                                                ).toDateString()}
                                              </small>
                                            </div>
                                          </div>
                                          <div></div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </InfiniteScroll>
                            {/* </div> */}
                          </div>
                        </div>
                        {/* new table ends here */}
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
