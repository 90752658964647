import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// import { useGet } from "../SearchComponent/useSearchItems";
import { usePost, useGet } from "../Common/useApi";
// import { usePost, useGet } from "../../../Services/UserService/UserService";
import AuthUser from "../Common/AuthUser";
import { useNavigate, navigation } from "react-router-dom";
import PathConstants from "../../routes/PathConstants";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import BookingService from "../../Services/BookingService";
import "./book-ride.css";
import Form from "react-bootstrap/Form";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import { toast } from "react-toastify";
import FiltersConstants from "../../constants/FiltersConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import ShareService from "../Share/Services/ShareService";
import ReactDOM from "react-dom/client";

export default function BookRideComponent() {
  let { id } = useParams();
  const location = useLocation();
  const data = location.state ? location.state : {};
  const [loading, setLoading] = useState(true);
  const [rideDetail, setRideDetail] = useState([]);
  const { http, user } = AuthUser();
  const [inputs, setInputs] = useState({});
  const [passengers, setPassengers] = useState([
    { name: "", age: "", gender: "", phone: "" },
  ]);
  const filtersConstants = FiltersConstants();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });

  const navigate = useNavigate();
  const bookingService = useService(BookingService);
  const rideService = useService(RideService);

  useEffect(() => {
    setLoading(true);
    if (id) {
      console.log(id);
      rideService.get(id).subscribe(res => {
        console.log(res);
        setRideDetail(res.result);
        setLoading(false);
      });
    } else if (data) {
      console.log(data);
      setRideDetail(data);
      setLoading(false);
    }
  }, []);
  const getHoursFromMilliSec = ms => {
    return Math.trunc(ms / (1000 * 60 * 60));
  };

  const getMinsFromMilliSec = ms => {
    return Math.trunc((ms / (1000 * 60)) % 60);
  };
  function handleConfirmationFromChild(confirmation) {
    setOpenConfirmationDialog(false);
    // console.log(confirmation);
    if (confirmation) {
      // event.preventDefault();
      bookRide();
    }
  }
  const confirmCancelRide = () => { };
  const bookRide = () => {
    // let postData = { from: rideDetail.from, to: rideDetail.to };

    let postData = {
      ride_id: rideDetail._id,
      pickup: inputs.pickup,
      drop: inputs.drop,
      passengers: passengers,
    };

    console.log(postData);
    bookingService.post(postData).subscribe({
      next: response => {
        if (response.status !== 0) {
          console.log(response.result);
          navigate(PathConstants.BOOKING_DETAIL + "/" + response.result._id, {
            state: response.result,
          });
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "pickup" || name === "drop") {
      const index = event.target.options.selectedIndex;
      var id = event.target.options[index].getAttribute("id");
      setInputs(values => ({ ...values, [name]: id }));
    }
    // console.log(id);
    setRideDetail(values => ({ ...values, [name]: value }));
  };

  const handleAddPax = () => {
    setPassengers([...passengers, { name: "", age: "", gender: "" }]);
  };
  const handlePaxChange = (event, index) => {
    const name = event.target.name;
    const value = event.target.value;
    // setPassengers(values => ({ ...values, [name]: value }));
    let onChangeValue = [...passengers];
    onChangeValue[index][name] = value;
    setPassengers(onChangeValue);
  };

  const handleDeletePax = index => {
    const newArray = [...passengers];
    newArray.splice(index, 1);
    setPassengers(newArray);
  };

  const handleSubmit = event => {
    //confirm dialog
    var title = "Booking Confirmation";

    var body =
      "Do you want to proceed for booking? Once confirmed, it cannot be revoked.";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));
    // setConfirmationType(RideStatus.CANCELLED); //ConfirmationDialog.DELETE;
    setOpenConfirmationDialog(true);
    event.preventDefault();
    // bookRide();
  };
  function getSocialDecription(ride) {
    var from = ride.from_loc?.landmark ? ride.from_loc?.landmark : ride.from_loc?.city;
    var to = ride.to_loc?.landmark ? ride.to_loc?.landmark : ride.to_loc?.city;
    var time = new Date(
      parseInt(ride.departure)
    ).toDateString() +

      " @ " + new Date(
        parseInt(ride.departure)
      ).toLocaleTimeString("en-US", {
        hour: "2-digit",
        hour12: true, // Delete for 24-hour format
        minute: "2-digit",
      })
      ;
    return "Seats Available in Car Pool from " + from
      + " to " + to + " on " + time;

  }
  const socialShare = () => {
    // const domNode = ReactDOM.getElementById('root');
    const domNode = ReactDOM.createRoot(document.getElementById("social-share"));
    domNode.render(<ShareService description={getSocialDecription(rideDetail)} link={window.location.protocol + "//" +
      window.location.hostname + "/share/ride/" + rideDetail._id}></ShareService>);
    //  shareService.show();
  }
  function getBrandName(ride) {
    if (ride?.operator?.profile?.brand_name) {
      return ride.operator.profile.brand_name;
    }
    else if (ride?.operator?.name) {
      return ride.operator.name;
    }
  }
  return (
    rideDetail && (
      <div className="App">
        <ConfirmationDialog
          open={openConfirmationDialog}
          inputs={dialogParams}
          // close={closeDialog}
          // confirm={confirmDialog}
          sendConfirmationToParent={handleConfirmationFromChild}
        ></ConfirmationDialog>
        <div className="container mt-3">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="ride-detail">
              <div className="mx-0 row d-flex justify-content-center">
                <div className="px-0 col-md-10 col-lg-8 shadow card pb-3">
                  <div className="card-body p-1">
                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                    <div className="col">
                      <strong>Ride Detail</strong>
                      <div>{getBrandName(rideDetail)}</div>
                    </div>
                    <div className="text-right">
                      <div className="btn btn-outline-none"
                        onClick={socialShare}
                      >
                        <FontAwesomeIcon className="px-1" icon={faShare} />
                      </div>
                    </div>
                    <div id="social-share"></div>
                    <hr />
                    <div className="col">
                      <div className="row">
                        <div className="mb-2 col text-success">
                          <strong>
                            {new Date(
                              parseInt(rideDetail.departure)
                            ).toDateString()}
                          </strong>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-sm-12 col-md-8 border-bottom">
                          <div className="d-flex flex-row align-content-center">
                            <div className="text-break col col-md-4 mb-2 px-0">
                              <div className="text-capitalize">
                                {rideDetail.from_loc?.city}
                              </div>
                              <div className="text-muted text-capitalize">
                                {rideDetail.from_loc?.state}
                              </div>
                              <small>
                                {new Date(
                                  parseInt(rideDetail.departure)
                                ).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  hour12: true, // Delete for 24-hour format
                                  minute: "2-digit",
                                })}
                              </small>
                            </div>
                            <div className="align-self-center col col-md-4 mb-2">
                              {rideDetail.distance && <small>{rideDetail.distance} Km.</small>}
                              <hr className="my-0 flex-fill track-line" />
                              {rideDetail.arrival && <small>
                                {getHoursFromMilliSec(
                                  rideDetail.arrival - rideDetail.departure
                                )}
                                {" Hrs. "}
                                {getMinsFromMilliSec(
                                  rideDetail.arrival - rideDetail.departure
                                )}
                                {" Mins."}
                              </small>}
                            </div>
                            <div className="text-break col col-md-4 mb-2 px-0">
                              <div className="text-capitalize">
                                {rideDetail.to_loc?.city}
                              </div>
                              <div className="text-muted text-capitalize">
                                {rideDetail.to_loc?.state}
                              </div>
                              {rideDetail.arrival && <small>
                                {new Date(
                                  parseInt(rideDetail.arrival)
                                ).toLocaleTimeString("en-US", {
                                  hour: "2-digit",
                                  hour12: true, // Delete for 24-hour format
                                  minute: "2-digit",
                                })}
                              </small>}
                            </div>
                            {/* <span className="d-flex justify-content-center align-items-center big-dot dot">
                            <i className="fa fa-check text-dark"></i>
                          </span> */}
                          </div>
                        </div>
                        <div className="mb-3 border-bottom col text-center">
                          <div className="row">
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                  <strong>Rs. {rideDetail.fare}</strong>
                                </div>
                              </div>
                              <hr className="my-1" />
                              <div className="row">
                                <div className="col">
                                  {rideDetail.current_vacancy}
                                  {" of "} {rideDetail.maxpax} Avl
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col"> */}
                    <div className="col">
                      <div className="row">
                        <div className="col d-flex ">
                          <div className="d-flex flex-column">
                            <strong className="1lead 1fw-normal">
                              Start-End Location
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mt-3 col 1px-0 border-right1">
                          <div className="row align-items-center">
                            <div className="col px-2">
                              <small className="text-muted">Start</small>
                              <div>{rideDetail.from_loc?.landmark ? rideDetail.from_loc?.landmark : rideDetail.from_loc?.city} </div>
                            </div>
                            <div className="col-3 px-1">
                              <hr className="my-0 flex-fill track-line" />
                            </div>
                            <div className="col px-0">
                              <small className="text-muted">End</small>
                              <div>{rideDetail.to_loc?.landmark ? rideDetail.to_loc?.landmark : rideDetail.to_loc?.city}</div>
                            </div>
                            {/* <span className="d-flex justify-content-center align-items-center big-dot dot">
                            <i className="fa fa-check text-dark"></i>
                          </span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <hr />
                    <div className="col">
                      <div className="row">
                        <div className="col  border-right border-dark">
                          <div className="row">
                            <div className="col mb-2 text-left">
                              <strong className="1lead 1fw-normal">
                                Driver Detail
                              </strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <small className="text-capitalize 1fw-normal">
                                {rideDetail?.driver?.name}
                                {rideDetail?.driver?.phone
                                  ? " | " + rideDetail.driver.phone
                                  : ""}
                                {rideDetail?.driver?.age
                                  ? " | " + rideDetail.driver.age
                                  : ""}
                                {rideDetail?.driver?.gender
                                  ? " | " +
                                  filtersConstants.getGenderString(
                                    rideDetail.driver.gender
                                  )
                                  : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col mb-2 text-left">
                              <strong className="1lead 1fw-normal">
                                Vehicle Detail
                              </strong>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <small className="text-capitalize 1fw-normal">
                                {rideDetail?.vehicle?.brand}
                                {rideDetail?.vehicle?.vehicle_no
                                  ? " | " + rideDetail.vehicle.vehicle_no
                                  : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Form className="container" onSubmit={handleSubmit}>
          <div className="mt-5 row d-flex justify-content-center">
            <div className="mb-0 py-2 pb-0 h5 text-dark col-auto text-left">
              <strong>Enter Booking Details</strong>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-11 col-md-8 pt-1 border">
              <div data-mdb-input-init className="form-outline mb-4"></div>
              <Form.Select
                className="form-select form-select-lg is-1invalid"
                aria-label="Default select example"
                id="pickup"
                name="pickup"
                // placeholder={rideDetail.from_loc?.city}
                value={rideDetail.pickup ? rideDetail.pickup : ""}
                // onChange={handleSelectChangeDriver}
                onChange={handleChange}
                required
              >
                <option disabled value={""}>
                  Pickup Point
                </option>
                {rideDetail.from_loc?._id && (
                  <option
                    key={rideDetail.from_loc?._id}
                    id={rideDetail.from_loc?._id}
                    value={rideDetail.from_loc?.city}
                  >
                    {rideDetail.from_loc?.city}
                  </option>
                )}
              </Form.Select>
              <hr />
              <Form.Select
                className="form-select form-select-lg is-1invalid"
                aria-label="Default select example"
                id="drop"
                name="drop"
                value={rideDetail.drop ? rideDetail.drop : ""}
                // onChange={handleSelectChangeDriver}
                onChange={handleChange}
                required
              >
                <option disabled value={""}>
                  Drop Point
                </option>
                {rideDetail.to_loc?._id && (
                  <option
                    key={rideDetail.to_loc?._id}
                    id={rideDetail.to_loc?._id}
                    value={rideDetail.to_loc?.city}
                  >
                    {rideDetail.to_loc?.city}
                  </option>
                )}
              </Form.Select>
              <hr />
              {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              <div className="pt-3 row border-top border-success">
                <div className="col">
                  <div className="text-light bg-primary mb-3 row border-bottom border-success">
                    <div className="col text-left font-weight-bold">
                      <span className="">Passanger Detail</span>
                    </div>
                  </div>
                  {passengers.map((item, index) => (
                    <div
                      className="mb-4 row border-bottom border-success"
                      key={index}
                    >
                      <div className="col-12">
                        <input
                          type="string"
                          name="name"
                          id={"paxname" + index}
                          className="col form-control form-control-lg"
                          placeholder="Passanger Name"
                          // disabled
                          value={item.name || ""}
                          onChange={event => handlePaxChange(event, index)}
                          required
                        />
                        <hr />
                      </div>

                      <div className="col-12">
                        <input
                          type="number"
                          id={"age" + index}
                          name="age"
                          className="col form-control form-control-lg"
                          placeholder="Age"
                          min={5}
                          max={100}
                          value={item.age || ""}
                          onChange={event => handlePaxChange(event, index)}
                          required
                        />
                        <hr />
                      </div>
                      <Form.Select
                        className="form-select form-select-lg"
                        aria-label="Default select example"
                        id={"gender" + index}
                        name="gender"
                        type="string"
                        value={item.gender || ""}
                        // onChange={handleSelectChangeDriver}
                        onChange={event => handlePaxChange(event, index)}
                        required
                      >
                        <option disabled value={""}>
                          Gender
                        </option>

                        <option
                          key={filtersConstants.GenderFilters.MALE}
                          value={filtersConstants.GenderFilters.MALE}
                        >
                          {filtersConstants.GenderFilters.MALE_STRING}
                        </option>
                        <option
                          key={filtersConstants.GenderFilters.FEMALE}
                          value={filtersConstants.GenderFilters.FEMALE}
                        >
                          {filtersConstants.GenderFilters.FEMALE_STRING}
                        </option>
                        <option
                          key={filtersConstants.GenderFilters.TRANS}
                          value={filtersConstants.GenderFilters.TRANS}
                        >
                          {filtersConstants.GenderFilters.TRANS_STRING}
                        </option>
                      </Form.Select>
                      {/* <div className="col-12">
                        <input
                          type="string"
                          id={"gender" + index}
                          name="gender"
                          className="col form-control form-control-lg"
                          placeholder="Gender"
                          // disabled
                          value={item.gender || ""}
                          onChange={event => handlePaxChange(event, index)}
                          required
                        />
                      </div> */}
                      <div className="mt-2">
                        {index === passengers.length - 1 && (
                          <div
                            className="mx-1 mb-2 float-right btn border btn-warning"
                            onClick={() => handleAddPax()}
                          >
                            Add
                          </div>
                        )}
                        {passengers.length > 1 && (
                          <div
                            className="mx-1 float-right btn border btn-warning"
                            onClick={() => handleDeletePax(index)}
                          >
                            Delete
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <hr /> */}
              <div className="justify-content-center my-3 form-group row">
                <div className="col-sm-10">
                  <button type="submit" className="btn btn-primary">
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  );
}
