import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import RideService from "../../Services/RideService";
import useService from "../../hooks/useService";
import PathConstants from "../../routes/PathConstants";
import "../../styles/create-ride-form.css";
import "react-datepicker/dist/react-datepicker.css";
import DriverService from "../../Services/DriverService";
import VehicleService from "../../Services/VehicleService";
import { toast } from "react-toastify";
import Select from "react-select";
import AddNewLocationDialog from "../dialogs/AddNewLocationDialog";
import AuthUser from "../Common/AuthUser";
import RolesConstants from "../../constants/RolesConstants";
import StringUtils from "../../utils/StringUtils";
import AddNewDriverDialog from "../dialogs/AddNewDriverDialog";
import AddNewVehicleDialog from "../dialogs/AddNewVehicleDialog";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

export default function CreateUpdateRideComponent(props) {
  const [inputs, setInputs] = useState({ driver_id: "", vehicle_id: "" });
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [initialMsg, setInitialMsg] = useState(false);

  const [validated, setValidated] = useState(false);
  const [departureDate, setDepartureDate] = useState();
  const [arrivalDate, setArrivalDate] = useState();
  const [myDrivers, setMyDrivers] = useState();
  const [myVehicles, setMyVehicles] = useState();
  const [openNewLocationDialog, setOpenNewLocationDialog] = useState(false);
  const [openNewDriverDialog, setOpenNewDriverDialog] = useState(false);
  const [openNewVehicleDialog, setOpenNewVehicleDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const [errors, setErrors] = useState({ from: {}, to: {}, departure: {} });
  const { user } = AuthUser();
  const rideService = useService(RideService);
  const driverService = useService(DriverService);
  const vehicleService = useService(VehicleService);

  const [dialogParams, setDialogParams] = useState({
    title: "",
    body: "",
  });
  useEffect(() => {
    if (props.id) {
      //perform edit process
      if (user && user.role === RolesConstants.ADMIN) {
        console.log(props.id);
        getDrivers(props.id);
        getVehicles(props.id);
        getRide(props.id);
      } else {
        getMyDrivers();
        getMyVehicles();
      }

    } else {
      getMyDrivers();
      getMyVehicles();
    }

  }, [props]);

  const reinitializeErrors = () => {
    setErrors({ from: {}, to: {} });
    // setErrors(values => ({ ...values, [name]: value }));
  };

  const getRide = (id) => {
    setPageLoading(true);
    rideService.get(id).subscribe({
      next: response => {
        if (response.status !== 0) {
          setInputs(values => ({ ...values, ['id']: response.result?._id }));
          setInitialValues(response.result);
          setPageLoading(false);
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  }
  const getDrivers = (rideId) => {
    driverService.getDrivers({ ride_id: rideId }).subscribe(res => {
      // console.log(res.result?.data);
      setMyDrivers(res.result?.data);
    });
  };
  const getVehicles = (rideId) => {
    vehicleService.getVehicles({ ride_id: rideId }).subscribe(res => {
      console.log(res.result?.data);
      setMyVehicles(res.result?.data);
    });
  };
  const setInitialValues = (data) => {
    if (!data) {
      setLocations([]);
      return;
    } else {
      if (data.from_loc) {
        setInputs(values => ({ ...values, ['from']: data.from }));
      } if (data.to_loc) {
        setInputs(values => ({ ...values, ['to']: data.to }));
      } if (data.departure) {
        let value = (new Date(parseInt(data.departure)));
        setDepartureDate(StringUtils.dateToDateTimeString(value));
        setInputs(values => ({ ...values, ['departure']: data.departure }));

      } if (data.arrival) {
        let value = new Date(parseInt(data.arrival));

        setArrivalDate(StringUtils.dateToDateTimeString(value));
        setInputs(values => ({ ...values, ['arrival']: data.arrival }));
      } if (data.fare) {
        setInputs(values => ({ ...values, ['fare']: data.fare }));
      } if (data.maxpax) {
        setInputs(values => ({ ...values, ['maxpax']: data.maxpax }));
      } if (data.distance) {
        setInputs(values => ({ ...values, ['distance']: data.distance }));
      } if (data.driver_id) {
        setInputs(values => ({ ...values, ['driver_id']: data.driver_id }));
      } if (data.vehicle_id) {
        setInputs(values => ({ ...values, ['vehicle_id']: data.vehicle_id }));
      }
    }

  }
  const getMyDrivers = () => {
    driverService.getMyDrivers().subscribe(res => {
      console.log(res.result?.data);
      setMyDrivers(res.result?.data);
    });
  };

  const handleSelectChange = (event, type) => {
    reinitializeErrors();
    const name = type;
    console.log(event);
    var value = event?.id;

    setInputs(values => ({ ...values, [name]: value }));
  };

  const getSelectOptionLabel = option => {
    // console.log(option);
    // return option.label + " -> " + option.state + " (" + option.landmark + ")";
    let landmark = option.landmark ? option.landmark + ", " : "";
    return landmark + option.label + ", " + option.state;
    // return <div>test</div>;
  };
  const searchLocation = event => {
    if (event.altKey || event.ctrlKey || event.shiftKey) {
      return;
    }
    var value = event;//.target.value + event.key;
    // console.log(value);
    setLoading(true);
    rideService.searchLocation(value).subscribe({
      next: response => {
        if (response.status !== 0) {
          // console.log(response.result);
          var locations = [];
          response.result.map(location => {
            return locations.push({
              label: location.city,
              value: location.city,
              state: location.state,
              landmark: location.landmark,
              id: location._id,

              // + " : " + location.state, //location.landmark
              // // value: location._id,
              // value: location.state,
            });
          });
          setInitialMsg(false);
          setLocations(locations);
          // setLocations(values => ({ ...values, locations }));
          setLoading(false);
        }
      },
      error: err => {
        toast.dismiss();
        toast.error(err.message);
      },
    });
  };
  const setInitialLocationValues = event => {
    setInitialValues(null);
    setInitialMsg(true);
  };
  const notFoundMessage = event => {
    if (loading) {
      return `Loading`;
    } else if (!loading && !initialMsg) {
      // && (!locations || location.length <= 0)) {
      return "No result";
    } else {
      return "Search";
    }
  };
  const getMyVehicles = () => {
    vehicleService.getMyVehicles().subscribe(res => {
      // console.log(res.result?.data);
      setMyVehicles(res.result?.data);
    });
  };

  const validateAndCreate = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      var name = "",
        value = {};
      console.log(inputs.from);
      event.preventDefault();
      event.stopPropagation();
      if (!inputs.from) {
        name = "from";
        console.log("here");
        value = { required: true };

        setErrors(values => ({ ...values, [name]: value }));
      }
      if (!inputs.to) {
        name = "to";
        value = { required: true };

        setErrors(values => ({ ...values, [name]: value }));
      }
      setValidated(true);
      return;
    } else {
      if (inputs?.departure < new Date().getTime()) {
        alert("Departure cannot be in past");
        event.stopPropagation();
        setValidated(true);
        return;
      }

      // if (inputs?.departure > inputs?.arrival) {
      //   alert("Arrival cannot be before Departure");
      //   event.stopPropagation();
      //   setValidated(true);
      //   return;
      // }
      showConfirmationDialog();
    }
  };
  const showConfirmationDialog = event => {
    //confirm dialog
    var title = "Confirm?";

    var body =
      "Have you checked the ride details again?";
    setDialogParams(prevState => ({
      ...prevState,
      title: title,
      body: body,
    }));
    setOpenConfirmationDialog(true);
  }
  const handleChange = event => {
    const name = event.target.name;
    var value = event.target.value;
    if (name === "departure") {

      value = new Date(event.target.value).getTime();
      console.log(event.target.value);
      handleDepartureDateChange(event);
    } else if (name === "arrival") {
      value = new Date(event.target.value).getTime();
      handleArrivalDateChange(event);
    } else if (name === "fare" || name === "distance" || name === "maxpax") {
      value = parseInt(value);
    }
    setInputs(values => ({ ...values, [name]: value }));
    setInitialMsg(true);
  };

  const handleDepartureDateChange = event => {
    const val = event.target.value;
    setDepartureDate(val);
  };

  const handleArrivalDateChange = event => {
    const val = event.target.value;
    setArrivalDate(val);
  };

  const handleSubmit = event => {
    event.preventDefault();
    validateAndCreate(event);
  };

  const confirmCreateRide = () => {
    console.log(inputs);
    rideService.post(inputs).subscribe({
      next: rides => {
        if (rides.status && rides.result?._id) {
          toast.success(rides.message);
          navigate(PathConstants.RIDE_DETAIL + "/" + rides.result?._id, { state: rides.result });
        }
      },
      error: e => alert(e),
      complete: () => console.info("complete"),
    });
  }
  const createLocationDialog = () => {
    setOpenNewLocationDialog(true);
  };
  function handleConfirmationFromChild(confirmation) {
    if (openNewLocationDialog) {
      setOpenNewLocationDialog(false);
    }
    else if (openNewDriverDialog) {
      if (confirmation) {
        //reload dirivers
        if (props.id) {
          if (user && user.role === RolesConstants.ADMIN) {
            console.log(props.id);
            getDrivers(props.id);
          }
        } else {
          getMyDrivers();
        }
      }
      setOpenNewDriverDialog(false);
    } else if (openNewVehicleDialog) {
      if (confirmation) {
        if (props.id) {
          if (user && user.role === RolesConstants.ADMIN) {
            getVehicles(props.id);
          }
        } else {
          getMyVehicles();
        }
      }
      setOpenNewVehicleDialog(false);
    } else if (openConfirmationDialog) {
      setOpenConfirmationDialog(false);
      if (confirmation) {
        //create ride
        confirmCreateRide();
      }
    }
  }
  const createDriverDialog = () => {
    setOpenNewDriverDialog(true);
  };
  const createVehicleDialog = () => {
    setOpenNewVehicleDialog(true);
  };

  return (

    <div className="App create-ride-form py-5">
      {pageLoading && (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      <ConfirmationDialog
        open={openConfirmationDialog}
        inputs={dialogParams}
        sendConfirmationToParent={handleConfirmationFromChild}
      ></ConfirmationDialog>
      {!pageLoading && <>
        <AddNewLocationDialog
          open={openNewLocationDialog}
          sendConfirmationToParent={handleConfirmationFromChild}
        ></AddNewLocationDialog>
        <AddNewDriverDialog
          open={openNewDriverDialog}
          sendConfirmationToParent={handleConfirmationFromChild}
        ></AddNewDriverDialog>
        <AddNewVehicleDialog
          open={openNewVehicleDialog}
          sendConfirmationToParent={handleConfirmationFromChild}
        ></AddNewVehicleDialog>
        <div className="mask d-flex align-items-center h-100 1app-bg-color">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className="app-border-color card" style={{ borderRadius: 15 }}>
                  <div className="card-body p-3">
                    <Form
                      onSubmit={handleSubmit}
                      validated={validated}
                      noValidate
                    >
                      {!props.id && <div className="h4 text-left">New Ride</div>}
                      {props.id && <div className="h4 text-left">Edit Ride</div>}
                      {!props.id && <div className="text-right col">
                        <span
                          onClick={createLocationDialog}
                          className="text-primary btn"
                        >
                          Location not found? Add Here
                        </span>
                      </div>}
                      {!props.id && <div
                        data-mdb-input-init
                        className="text-left form-outline mb-4"
                      >
                        <Select
                          className={
                            errors?.from?.required
                              ? "is-invalid form-control"
                              : "form-control"
                          }
                          options={locations}
                          name="from"
                          placeholder="From"
                          onChange={event => handleSelectChange(event, "from")}

                          onInputChange={searchLocation}
                          noOptionsMessage={event => notFoundMessage()}
                          getOptionLabel={option => getSelectOptionLabel(option)}
                          onFocus={setInitialLocationValues}
                          required
                          isDisabled={props.id ? true : false}
                        />
                      </div>}
                      {/* {!props.id && <hr />} */}
                      {!props.id && <div
                        data-mdb-input-init
                        className="text-left form-outline mb-4"
                      >
                        <Select
                          className={
                            errors?.to?.required
                              ? "is-invalid form-control"
                              : "form-control"
                          }
                          options={locations}
                          name="to"
                          placeholder="To"
                          onChange={event => handleSelectChange(event, "to")}
                          // onKeyDown={searchLocation}
                          onInputChange={searchLocation}
                          noOptionsMessage={event => notFoundMessage()}
                          getOptionLabel={option => getSelectOptionLabel(option)}
                          // }
                          onFocus={setInitialLocationValues}
                          required
                          isDisabled={props.id ? true : false}
                        />
                      </div>}
                      {/* <hr /> */}
                      <div data-mdb-input-init className="form-outline mb-4">
                        <input
                          type="datetime-local"
                          className="form-control form-control-lg"
                          name="departure"
                          placeholder="Departure Time"
                          value={departureDate || ""}
                          onChange={handleChange}
                          required
                        />
                        <label className="col small text-left text-muted">
                          Departure Date & Time
                        </label>
                      </div>
                      {/* <hr /> */}
                      {/* <div data-mdb-input-init className="form-outline mb-4">
                        <input
                          type="datetime-local"
                          // timeFormat="HH:mm"
                          className="form-control form-control-lg"
                          name="arrival"
                          placeholder="Arrival Time"
                          value={arrivalDate || ""}
                          onChange={handleChange}
                          required
                        />
                        <label className="col small text-left text-muted">
                          Arrival Date & Time
                        </label>
                      </div>
                      <hr /> */}
                      <div data-mdb-input-init className="form-outline mb-4">
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          name="fare"
                          min="10"
                          placeholder="Fare Per Passenger"
                          value={inputs.fare || ""}
                          onChange={handleChange}
                          required
                        />
                        <label className="col small text-left text-muted">
                          In India Rupees (Min: 10 Rs.)
                        </label>
                      </div>

                      {/* <hr /> */}
                      <div data-mdb-input-init className="form-outline mb-4">
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          name="maxpax"
                          max="10"
                          min="1"
                          placeholder="Seats Available"
                          value={inputs.maxpax || ""}
                          onChange={handleChange}
                          required
                        />
                        <label className="col small text-left text-muted">
                          Except driver seat (Min: 1, Max: 10)
                        </label>
                      </div>

                      {/* <hr /> */}
                      {/* <div data-mdb-input-init className="form-outline mb-4">
                        <input
                          type="number"
                          className="form-control form-control-lg"
                          name="distance"
                          max="5000"
                          min="1"
                          placeholder="Total Distance"
                          value={inputs.distance || ""}
                          onChange={handleChange}
                          required
                        />
                        <label className="col small text-left text-muted">
                          In Km. (Min: 1 Km, Max: 5000 Km)
                        </label>
                      </div>

                      <hr /> */}
                      <Form.Select
                        className="text-capitalize form-select form-select-lg is-1invalid"
                        aria-label="Default select example"
                        id="driver_id"
                        name="driver_id"
                        value={inputs.driver_id || ""}
                        // onChange={handleSelectChangeDriver}
                        onChange={handleChange}
                        required
                      >
                        <option disabled value={""}>
                          Select Driver
                        </option>
                        {myDrivers &&
                          myDrivers.map(driver => {
                            return (
                              <option key={driver._id} value={driver._id}>
                                {driver.name + " (" + driver.phone + ")"}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <div className="text-right col">
                        <span
                          onClick={createDriverDialog}
                          className="text-primary btn"
                        >
                          Driver not listed? Add Here
                        </span>
                      </div>
                      {/* <hr /> */}
                      <Form.Select
                        className="text-capitalize form-select form-select-lg"
                        aria-label="Default select example"
                        name="vehicle_id"
                        value={inputs.vehicle_id || ""}
                        onChange={handleChange}
                        required
                      >
                        <option disabled value={""}>
                          Select Vehicle
                        </option>
                        {myVehicles &&
                          myVehicles.map(vehicle => {
                            return (
                              <option
                                className="text-capitalize"
                                key={vehicle._id}
                                value={vehicle._id}
                              >
                                {vehicle.brand + " - " + vehicle.vehicle_no}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <div className="text-right col">
                        <span
                          onClick={createVehicleDialog}
                          className="text-primary btn"
                        >
                          Vehicle not listed? Add Here
                        </span>
                      </div>
                      <input className="app-bg-color text-white mt-3 col btn 1btn-dark" type="submit" />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </div>
  );
}
